.search-bar {
  display: block;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  padding: 10px 10px 0;
  background-color: var(--bg-body);
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;

  &.is-active {
    visibility: visible;
    opacity: 1;
  }

  .categories-bar {
    margin-bottom: 20px;
  }

  &__container {
    max-width: 768px;
    margin: 0 auto;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &__close-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 38px;
    background-color: var(--white);
    box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
    border: none;
    border-radius: 50%;

    .icon-close {
      width: 16px;
      height: 16px;

      path {
        fill: #000;
      }
    }
  }

  &__title {
    position: relative;
    display: flex;
    align-items: center;
    margin: 10px 0;
    font-size: 18px;
    white-space: nowrap;

    &::after {
      content: '';
      position: absolute;
      z-index: 10;
      bottom: 0;
      left: 0;
      transform: translateY(calc(100% + 10px));
      width: 100%;
      height: 5px;
      background: linear-gradient(0deg, rgb(16 14 16 / 0%) 0, var(--bg-body) 100%);
    }

    &-label {
      padding-right: 10px;
      font-size: 18px;
      font-weight: 800;
      color: var(--white);
    }

    &-counter {
      font-style: normal;
      font-weight: 600;
      color: var(--f-grey-4);
    }

    &-text {
      font-size: 12px;
      color: var(--f-grey-9);
      font-weight: 600;

      span {
        color: var(--white);
        font-weight: 800;
      }
    }
  }

  &__item-container {
    overflow-x: hidden;
    max-height: calc(100vh - 163px);
    margin-top: 10px;

    &.is-second {
      max-height: calc(100vh - 216px);
    }
  }

  &__scroll-container {
  }

  @include breakpoint-max('359px') {
    &__close-btn {
      width: 35px;
      height: 35px;
    }

    &__item-container {
      min-height: calc(100vh - 160px);
    }
  }

  &__form {
    flex: 1;
    position: relative;
    display: flex;
    width: 100%;
    padding-left: 10px;

    &-btns {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      display: flex;

      .btn {
        margin-right: 2px;
      }
    }
  }

  &__label {
    width: 100%;
  }

  &__input {
    width: 100%;
    padding: 13px 110px 12px 13px;
    color: var(--white);
    font-size: 14px;
    font-weight: bold;
    background-color: #000;
    border-radius: 20px;
    border: none;

    &::placeholder {
      color: var(--white);
    }
  }

  &__submit-btn {
    height: 30px;
  }

  &__reset-btn {
    min-width: 62px;
    height: 30px;
    font-size: 12px;
  }

  @include breakpoint-max('359px') {
    &__input {
      font-size: 11px;
      padding: 13px 110px 12px 10px;
    }
  }
}
