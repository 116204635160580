.right-menu {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;

  &__registration {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: var(--space-3);
    margin-bottom: var(--space-6);
    background-color: var(--registration-info);
    border-radius: var(--radius-s);
    span {
      flex: 1 0 50%;
      color: var(--warning-50);
      padding-left: var(--space-3);
      font-size: 12px;
      font-weight: 600;
    }
    &-button {
      flex: 1 0 100%;
      height: 32px;
      margin-top: var(--space-3);
      background-color: var(--registration-info-button);
      font-size: 12px;
      font-weight: 700;
    }
  }

  &__container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &.is-active {
    .right-menu__inner {
      transform: translateX(0);
    }
  }

  &__inner {
    z-index: 1002;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    width: 320px;
    height: 100%;
    padding-top: calc(20px + var(--safe-area-top));
    margin-top: var(--top-space-height);
    background-color: var(--menu-bg, var(--f-grey-19));
    transform: translateX(calc(100% + 100px));
    transition: all 0.3s ease;
  }

  &__shadow {
    z-index: 1001;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background-color: var(--menu-bg, var(--black));
    animation: fadeIn 0.3s forwards;

    @keyframes fadeIn {
      from {
        opacity: 0;
      }

      to {
        opacity: 0.7;
      }
    }
  }

  &__header {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: flex-end;
    padding: 0 var(--space-5) var(--space-3) var(--space-6);

    &-login {
      padding-right: var(--space-4);
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: var(--white);
    }

    &-close {
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon-cp {
        height: 20px;
        width: 20px;
      }
    }
  }

  &__content {
    position: relative;
    z-index: 5;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    padding: var(--space-7) var(--space-5) var(--space-9);
    display: flex;
    flex-direction: column;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__cash {
    &-title {
      display: block;
      margin-bottom: var(--space-7);
      font-size: 24px;
      font-weight: 800;
      color: var(--white);

      span {
        font-weight: 600;
        color: var(--f-grey-22);
      }
    }

    &-container {
      margin-bottom: var(--space-4);
    }

    &-col {
      display: flex;
      justify-content: space-between;
    }

    &-label {
      --cash-label-color: #8d8d92;
      margin-bottom: var(--space-4);
      font-size: 14px;
      font-weight: 700;
      color: var(--cash-label-color, var(--f-grey-12));
    }

    &-text {
      font-size: 14px;
      font-weight: 700;
      color: var(--white);
    }

    &-buttons {
      display: flex;
      justify-content: space-between;
      margin-bottom: var(--space-8);

      .btn {
        width: 48%;
        height: 40px;
        padding: var(--sapce-1);
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
        border-radius: var(--btn-radius, var(--radius-1));
      }

      .btn-primary--outline {
        background: var(--btn-menu-border, var(--bg-primary));
      }
    }
  }

  &__container-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    padding: var(--space-2);
    background-color: var(--pwa-icon-container-bg, var(--bg-primary));
    border-radius: var(--container-icon, var(--radius-1));
  }

  .side-menu__container-icon {
    position: relative;
  }

  &__lottery {
    margin-bottom: var(--space-8);
    border-radius: var(--lottery-radius, var(--radius-2));
    background-color: var(--bg-lottery, var(--f-grey-20));

    &-title {
      padding: var(--space-3) var(--space-4) var(--space-3) var(--space-3);
      display: block;
      font-size: 14px;
      border-bottom: 2px solid var(--lottery-title-bg, var(--f-grey-19));
    }

    &-label {
      display: block;
      padding-bottom: var(--space-1);
      font-weight: 700;
      color: var(--f-grey-12);
    }

    &-level {
      font-weight: 700;
      color: var(--white);
    }

    &-content {
      padding: var(--space-3) var(--space-4) var(--space-7) var(--space-3);
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-progress {
      position: relative;
      width: 100%;
      height: 4px;
      background-color: var(--bg-primary);
      border-radius: var(--progress-radius, var(--radius-3));

      &-bar {
        position: relative;
        height: 100%;
        width: 44%;
        background-color: var(--bg-green);
        border-radius: var(--bar-radius, var(--radius-3));
      }

      &-percent {
        position: absolute;
        bottom: 0;
        transform: translate(0, 20px);
        font-size: 12px;
        font-weight: 700;
        color: var(--bg-green);
      }
    }

    &-img {
      width: 31px;
      height: 35px;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
    }

    &-buttons {
      display: flex;
      padding: var(--space-3) var(--space-4) var(--space-4) var(--space-3);

      .btn-primary {
        flex: 1;
        width: 120px;
        height: 40px;
        margin-right: var(--space-3);
        font-weight: 600;
        letter-spacing: 0;
        border-color: transparent;
        background-color: var(--btn-primary-bg, var(--f-grey-23));
        border-radius: var(--btn-primary-radius, var(--radius-1));
      }

      .btn-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        margin-right: var(--space-3);
        padding: 0;
        border-radius: var(--radius-1);

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &__links {
    margin-bottom: var(--links-m, var(--space-3)) 0;

    li {
      margin-bottom: var(--space-6);
    }

    //li:last-child {
    //  margin-bottom: 0;
    //}

    &--secondary {
      li {
        margin-bottom: var(--space-4);
      }
    }
  }

  &__link {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    color: var(--white);
    font-size: 14px;
    font-weight: bold;
    line-height: 19px;

    .icon-my-bets {
      path {
        fill: var(--icon-my-bets-bg, var(--f-grey-23));
      }
    }

    span {
      padding-left: var(--space-4);
    }

    &.is-active {
      .side-menu__container-icon {
        background-color: var(--category-bg-active, var(--active-color));
      }
      span {
        color: var(--bg-brand);
      }
    }

    &--secondary {
      font-size: 13px;
      font-weight: 600;
      color: var(--link-color-2, var(--f-grey-4));
    }
  }

  .inbox-indicator ~ .side-menu__container-icon {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: calc(0px - 2px);
      width: 8px;
      height: 8px;
      background-color: var(--bg-inbox, var(--bg-red-2));
      border-radius: 50%;
    }
  }

  &__session {
    margin-top: auto;

    p {
      color: var(--primary-color);
      font-size: 11px;
      font-weight: 600;
      letter-spacing: -0.05em;
    }
  }

  &__links-summary {
    span {
      color: var(--links-summary-color, var(--f-grey-24));
      font-size: 14px;
      font-weight: 400;
      letter-spacing: -0.02em;
    }
  }

  .icon-cp {
    width: 20px;
    height: 20px;
  }

  .icon-logout,
  .icon-user {
    width: 16px;
    height: 16px;
  }
}
