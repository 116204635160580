html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address,
big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl,
dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas,
details, embed,  figure, figcaption, footer, header,  menu, nav, output, ruby, section, summary, time, mark, audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  letter-spacing: -0.05em;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, menu, nav, section {
  display: block;
}

ol, ul {
  list-style: none;
}

blockquote, q {	quotes: none;}

blockquote::before, blockquote::after, q::before, q::after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse; border-spacing: 0;
}

a {
  text-decoration: none;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
}

select {
  outline: none;
}