@mixin breakpoint-min($val) {
    @media screen and (min-width: $val) {
        @content;
    }
}

@mixin breakpoint-max($val) {
    @media screen and (max-width: $val) {
        @content;
    }
}

@mixin breakpoint($val) {
    @media screen and (max-width: $val) {
        @content;
    }
}


@mixin ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin gradient-slider {
    @include breakpoint-min('768px') {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 54px;
            right: 3.5%;
            height: calc(100% - 76px);
            width: 5%;
            background: linear-gradient(270deg, #000 0%, rgb(0 0 0 / 0%) 100%);
            transform: translateX(99%);
        }
    }
}

@mixin slider-icon {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 22px;
    margin-left: 4px;
    margin-bottom: 4px;
    padding: 0 7px;
    font-size: 11px;
    font-weight: 600;
    line-height: 0.5;
    letter-spacing: -0.07em;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-radius: 20px;
}

@mixin place-table($color) {
  .table-rank__cell--place {
    color: $color;
  }

  .table-rank__cell--name {
    color: $color;
  }
}

@mixin radius-bottom ($val) {
    border-bottom-right-radius: $val;
    border-bottom-left-radius: $val;
}

@mixin radius-top ($val) {
    border-top-left-radius: $val;
    border-top-right-radius: $val;
}

@mixin radius-left ($val) {
    border-top-left-radius: $val;
    border-bottom-left-radius: $val;
}

@mixin radius-right ($val) {
    border-top-right-radius: $val;
    border-bottom-right-radius: $val;
}

@mixin clear-mb {
    &:last-child {
        margin-bottom: 0;
    }
}
