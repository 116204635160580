//BREADCRUMBS
.breadcrumbs {
  display: flex;
  padding: 0 10px 15px;
  max-width: var(--container-max-width);
  margin: 0 auto;

  &__list {
    overflow: auto;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 15px;
    padding: 0 15px;
  }

  &__filters {
    z-index: 1;
    display: grid;
    grid-auto-flow: column;
    grid-gap: var(--breadcrumb-gap, 15px);
    margin-left: auto;
  }
}

//BREADCRUMS SIGNLE ENTRY
.breadcrumbs-entry {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 47px;
  flex-shrink: 0;
  color: var(--breadcrumb-color, var(--f-grey-1));
  font-size: 10px;
  font-weight: 700;
  letter-spacing: -0.06em;

  &--back {
    z-index: 1;

    // box-shadow: 4px 0px 4px 8px var(--bg-body);
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 47px;
    margin-bottom: 6px;
    border-radius: var(--breadcrumb-radius, var(--radius-primary));
    border: var(--breadcrumb-border, 2px solid var(--line-primary));
    text-align: center;

    &--item {
      background-color: var(--breadcrumb-bg, var(--bg-primary));
    }
  }

  &--game-logo {
    background-color: var(--breadcrumb-bg, var(--bg-primary));
    background-repeat: no-repeat;
    background-size: 78%;
    background-position: center;
  }

  &__name {
    @include ellipsis;

    width: 100%;
    text-align: center;
  }

  &__counter {
    position: absolute;
    top: -5px;
    right: -5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 21px;
    height: 21px;
    background-color: var(--breadcrumb-counter-bg, var(--bg-primary));
    color: var(--breadcrumb-counter-color, var(--white));
    font-size: 14px;
    font-weight: 800;
    line-height: 1;
    border-radius: 50%;
  }
}
