.flag-icon {
  display: inline-block;
  width: 22px;
  height: 22px;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 100%;
}

.flag {
  background-image: url($images-path-static + 'countries/world.svg');
}

.img-icon.afghanistan,
.flag-af {
  background-image: url($images-path-static + 'countries/afghanistan.svg');
}

.img-icon.africa {
  background-image: url($images-path-static + 'countries/africa.svg');
}

.flag-ax,
.img-icon.aland-islands {
  background-image: url($images-path-static + 'countries/aland-islands.svg');
}

.img-icon.albania,
.flag-al {
  background-image: url($images-path-static + 'countries/albania.svg');
}

.img-icon.algeria,
.flag-dz {
  background-image: url($images-path-static + 'countries/algeria.svg');
}

.img-icon.america {
  background-image: url($images-path-static + 'countries/america.svg');
}

.img-icon.american-samoa,
.flag-as {
  background-image: url($images-path-static + 'countries/american-samoa.svg');
}

.img-icon.andorra,
.flag-ad {
  background-image: url($images-path-static + 'countries/andorra.svg');
}

.img-icon.angola,
.flag-ao {
  background-image: url($images-path-static + 'countries/angola.svg');
}

.img-icon.anguilla,
.flag-ai {
  background-image: url($images-path-static + 'countries/anguilla.svg');
}

.img-icon.antigua-and-barbuda,
.flag-ag {
  background-image: url($images-path-static + 'countries/antigua-and-barbuda.svg');
}

.img-icon.argentina,
.flag-ar {
  background-image: url($images-path-static + 'countries/argentina.svg');
}

.img-icon.armenia,
.flag-am {
  background-image: url($images-path-static + 'countries/armenia.svg');
}

.img-icon.aruba,
.flag-aw {
  background-image: url($images-path-static + 'countries/aruba.svg');
}

.img-icon.asia {
  background-image: url($images-path-static + 'countries/asia.svg');
}

.img-icon.australia,
.flag-au {
  background-image: url($images-path-static + 'countries/australia.svg');
}

.img-icon.austria,
.img-icon.de,
.flag-at {
  background-image: url($images-path-static + 'countries/austria.svg');
}

.img-icon.azerbaijan,
.flag-az {
  background-image: url($images-path-static + 'countries/azerbaijan.svg');
}

.img-icon.bahamas,
.flag-bs {
  background-image: url($images-path-static + 'countries/bahamas.svg');
}

.img-icon.bahrain,
.flag-bh {
  background-image: url($images-path-static + 'countries/bahrain.svg');
}

.img-icon.bangladesh,
.flag-bd {
  background-image: url($images-path-static + 'countries/bangladesh.svg');
}

.img-icon.barbados,
.flag-bb {
  background-image: url($images-path-static + 'countries/barbados.svg');
}

.img-icon.belarus,
.flag-by {
  background-image: url($images-path-static + 'countries/belarus.svg');
}

.img-icon.belgium,
.flag-be {
  background-image: url($images-path-static + 'countries/belgium.svg');
}

.img-icon.belize,
.flag-bz {
  background-image: url($images-path-static + 'countries/belize.svg');
}

.img-icon.benin,
.flag-bj {
  background-image: url($images-path-static + 'countries/benin.svg');
}

.img-icon.bermuda,
.flag-bm {
  background-image: url($images-path-static + 'countries/bermuda.svg');
}

.img-icon.bhutan,
.flag-bt {
  background-image: url($images-path-static + 'countries/bhutan.svg');
}

.img-icon.bolivia,
.flag-bo {
  background-image: url($images-path-static + 'countries/bolivia.svg');
}

.flag-bq,
.img-icon.bonaire-saint-eustatius-and-saba {
  background-image: url($images-path-static + 'countries/bonaire-saint-eustatius-and-saba.svg');
}

.img-icon.bosnia-and-herzegovina,
.flag-ba {
  background-image: url($images-path-static + 'countries/bosnia-and-herzegovina.svg');
}

.img-icon.botswana,
.flag-bw {
  background-image: url($images-path-static + 'countries/botswana.svg');
}

.img-icon.bouvet-island,
.flag-bv {
  background-image: url($images-path-static + 'countries/bouvet-island.svg');
}

.img-icon.brazil,
.img-icon.br,
.flag-br {
  background-image: url($images-path-static + 'countries/brazil.svg');
}

.img-icon.british-indian-ocean-territory,
.flag-io {
  background-image: url($images-path-static + 'countries/british-indian-ocean-territory.svg');
}

.flag-bn,
.img-icon.brunei-darussalam {
  background-image: url($images-path-static + 'countries/brunei-darussalam.svg');
}

.img-icon.bulgaria,
.flag-bg {
  background-image: url($images-path-static + 'countries/bulgaria.svg');
}

.img-icon.burkina-faso,
.flag-bf {
  background-image: url($images-path-static + 'countries/burkina-faso.svg');
}

.img-icon.burundi,
.flag-bi {
  background-image: url($images-path-static + 'countries/burundi.svg');
}

.img-icon.cambodia,
.flag-kh {
  background-image: url($images-path-static + 'countries/cambodia.svg');
}

.img-icon.cameroon,
.flag-cm {
  background-image: url($images-path-static + 'countries/cameroon.svg');
}

.img-icon.canada,
.flag-ca {
  background-image: url($images-path-static + 'countries/canada.svg');
}

.img-icon.cape-verde,
.flag-cv {
  background-image: url($images-path-static + 'countries/cape-verde.svg');
}

.img-icon.cayman-islands,
.flag-ky {
  background-image: url($images-path-static + 'countries/cayman-islands.svg');
}

.img-icon.central-african-republic,
.flag-cf {
  background-image: url($images-path-static + 'countries/central-african-republic.svg');
}

.img-icon.chad,
.flag-td {
  background-image: url($images-path-static + 'countries/chad.svg');
}

.img-icon.chile,
.flag-cl {
  background-image: url($images-path-static + 'countries/chile.svg');
}

.img-icon.china,
.flag-cn {
  background-image: url($images-path-static + 'countries/china.svg');
}

.img-icon.chinese-taipei {
  background-image: url($images-path-static + 'countries/chinese-taipei.svg');
}

.img-icon.christmas-island,
.flag-cx {
  background-image: url($images-path-static + 'countries/christmas-island.svg');
}

.flag-cc,
.img-icon.cocos-keeling-islands {
  background-image: url($images-path-static + 'countries/cocos-keeling-islands.svg');
}

.img-icon.colombia,
.flag-co {
  background-image: url($images-path-static + 'countries/colombia.svg');
}

.img-icon.comoros,
.flag-km {
  background-image: url($images-path-static + 'countries/comoros.svg');
}

.img-icon.congo,
.flag-cd {
  background-image: url($images-path-static + 'countries/congo.svg');
}

.img-icon.cook-islands,
.flag-ck {
  background-image: url($images-path-static + 'countries/cook-islands.svg');
}

.img-icon.costa-rica,
.flag-cr {
  background-image: url($images-path-static + 'countries/costa-rica.svg');
}

.flag-ci,
.img-icon.cote-d-ivoire {
  background-image: url($images-path-static + 'countries/cote-d-ivoire.svg');
}

.img-icon.croatia,
.flag-hr {
  background-image: url($images-path-static + 'countries/croatia.svg');
}

.img-icon.cuba,
.flag-cu {
  background-image: url($images-path-static + 'countries/cuba.svg');
}

.flag-cw,
.img-icon.curacao {
  background-image: url($images-path-static + 'countries/curacao.svg');
}

.img-icon.cyprus,
.flag-cy {
  background-image: url($images-path-static + 'countries/cyprus.svg');
}

.img-icon.czech-republic,
.flag-cz {
  background-image: url($images-path-static + 'countries/czech-republic.svg');
}

.img-icon.denmark,
.flag-dk {
  background-image: url($images-path-static + 'countries/denmark.svg');
}

.img-icon.djibouti,
.flag-dj {
  background-image: url($images-path-static + 'countries/djibouti.svg');
}

.img-icon.dominica,
.flag-dm {
  background-image: url($images-path-static + 'countries/dominica.svg');
}

.img-icon.dominican-republic,
.flag-do {
  background-image: url($images-path-static + 'countries/dominican-republic.svg');
}

.img-icon.dutch-east-indies {
  background-image: url($images-path-static + 'countries/dutch-east-indies.svg');
}

.img-icon.east-germany {
  background-image: url($images-path-static + 'countries/east-germany.svg');
}

.img-icon.ecuador,
.flag-ec {
  background-image: url($images-path-static + 'countries/ecuador.svg');
}

.img-icon.egypt,
.flag-eg {
  background-image: url($images-path-static + 'countries/egypt.svg');
}

.img-icon.el-salvador,
.flag-sv {
  background-image: url($images-path-static + 'countries/el-salvador.svg');
}

.img-icon.england {
  background-image: url($images-path-static + 'countries/england.svg');
}

.img-icon.en {
  background-image: url($images-path-static + 'countries/united-kingdom.svg');
}

.img-icon.equatorial-guinea,
.flag-gq {
  background-image: url($images-path-static + 'countries/equatorial-guinea.svg');
}

.img-icon.eritrea,
.flag-er {
  background-image: url($images-path-static + 'countries/eritrea.svg');
}

.img-icon.estonia,
.flag-ee {
  background-image: url($images-path-static + 'countries/estonia.svg');
}

.img-icon.ethiopia,
.flag-et {
  background-image: url($images-path-static + 'countries/ethiopia.svg');
}

.img-icon.europe {
  background-image: url($images-path-static + 'countries/europe.svg');
}

.flag-fk,
.img-icon.falkland-islands-malvinas {
  background-image: url($images-path-static + 'countries/falkland-islands-malvinas.svg');
}

.img-icon.faroe-islands,
.flag-fo {
  background-image: url($images-path-static + 'countries/faroe-islands.svg');
}

.flag-fm,
.img-icon.federated-states-of-micronesia {
  background-image: url($images-path-static + 'countries/federated-states-of-micronesia.svg');
}

.img-icon.fiji,
.flag-fj {
  background-image: url($images-path-static + 'countries/fiji.svg');
}

.img-icon.finland,
.flag-fi {
  background-image: url($images-path-static + 'countries/finland.svg');
}

.img-icon.france,
.flag-fr {
  background-image: url($images-path-static + 'countries/france.svg');
}

.img-icon.french-guiana,
.flag-gf {
  background-image: url($images-path-static + 'countries/french-guiana.svg');
}

.img-icon.french-polynesia,
.flag-pf {
  background-image: url($images-path-static + 'countries/french-polynesia.svg');
}

.img-icon.french-southern-territories {
  background-image: url($images-path-static + 'countries/french-southern-territories.svg');
}

.img-icon.gabon,
.flag-ga {
  background-image: url($images-path-static + 'countries/gabon.svg');
}

.img-icon.gambia,
.flag-gm {
  background-image: url($images-path-static + 'countries/gambia.svg');
}

.img-icon.georgia,
.flag-ge {
  background-image: url($images-path-static + 'countries/georgia.svg');
}

.img-icon.germany,
.flag-de {
  background-image: url($images-path-static + 'countries/germany.svg');
}

.img-icon.ghana,
.flag-gh {
  background-image: url($images-path-static + 'countries/ghana.svg');
}

.img-icon.gibraltar,
.flag-gi {
  background-image: url($images-path-static + 'countries/gibraltar.svg');
}

.img-icon.great-britain,
.flag-ac {
  background-image: url($images-path-static + 'countries/great-britain.svg');
}

.img-icon.greece,
.flag-gr {
  background-image: url($images-path-static + 'countries/greece.svg');
}

.img-icon.greenland,
.flag-gl {
  background-image: url($images-path-static + 'countries/greenland.svg');
}

.img-icon.grenada,
.flag-gd {
  background-image: url($images-path-static + 'countries/grenada.svg');
}

.img-icon.guadeloupe,
.flag-gp {
  background-image: url($images-path-static + 'countries/guadeloupe.svg');
}

.img-icon.guam,
.flag-gu {
  background-image: url($images-path-static + 'countries/guam.svg');
}

.img-icon.guatemala,
.flag-gt {
  background-image: url($images-path-static + 'countries/guatemala.svg');
}

.img-icon.guernsey,
.flag-gg {
  background-image: url($images-path-static + 'countries/guernsey.svg');
}

.img-icon.guinea,
.flag-gn {
  background-image: url($images-path-static + 'countries/guinea.svg');
}

.img-icon.guinea-bissau,
.flag-gw {
  background-image: url($images-path-static + 'countries/guinea-bissau.svg');
}

.img-icon.guyana,
.flag-gy {
  background-image: url($images-path-static + 'countries/guyana.svg');
}

.img-icon.haiti,
.flag-ht {
  background-image: url($images-path-static + 'countries/haiti.svg');
}

.img-icon.heard-island-and-mcdonald-islands,
.flag-hm {
  background-image: url($images-path-static + 'countries/heard-island-and-mcdonald-islands.svg');
}

.flag-va,
.img-icon.holy-see-vatican-city-state {
  background-image: url($images-path-static + 'countries/holy-see-vatican-city-state.svg');
}

.img-icon.honduras,
.flag-hn {
  background-image: url($images-path-static + 'countries/honduras.svg');
}

.img-icon.hong-kong,
.flag-hk {
  background-image: url($images-path-static + 'countries/hong-kong.svg');
}

.img-icon.hungary,
.img-icon.hu,
.flag-hu {
  background-image: url($images-path-static + 'countries/hungary.svg');
}

.img-icon.iceland,
.flag-is {
  background-image: url($images-path-static + 'countries/iceland.svg');
}

.img-icon.india,
.flag-in {
  background-image: url($images-path-static + 'countries/india.svg');
}

.img-icon.indonesia,
.flag-id {
  background-image: url($images-path-static + 'countries/indonesia.svg');
}

.img-icon.iran,
.flag-ir {
  background-image: url($images-path-static + 'countries/iran.svg');
}

.img-icon.iraq,
.flag-iq {
  background-image: url($images-path-static + 'countries/iraq.svg');
}

.img-icon.isle-of-man,
.flag-im {
  background-image: url($images-path-static + 'countries/isle-of-man.svg');
}

.img-icon.israel,
.flag-il {
  background-image: url($images-path-static + 'countries/israel.svg');
}

.img-icon.ireland,
.flag-ie {
  background-image: url($images-path-static + 'countries/ireland.svg');
}

.img-icon.italy,
.flag-it {
  background-image: url($images-path-static + 'countries/italy.svg');
}

.img-icon.jamaica,
.flag-jm {
  background-image: url($images-path-static + 'countries/jamaica.svg');
}

.img-icon.japan,
.flag-jp {
  background-image: url($images-path-static + 'countries/japan.svg');
}

.img-icon.jersey,
.flag-je {
  background-image: url($images-path-static + 'countries/jersey.svg');
}

.img-icon.jordan,
.flag-jo {
  background-image: url($images-path-static + 'countries/jordan.svg');
}

.img-icon.kazakhstan,
.flag-kz {
  background-image: url($images-path-static + 'countries/kazakhstan.svg');
}

.img-icon.kenya,
.flag-ke {
  background-image: url($images-path-static + 'countries/kenya.svg');
}

.img-icon.kiribati,
.flag-ki {
  background-image: url($images-path-static + 'countries/kiribati.svg');
}

.flag-kp,
.img-icon.korea-north {
  background-image: url($images-path-static + 'countries/korea-north.svg');
}

.flag-kr,
.img-icon.korea-south {
  background-image: url($images-path-static + 'countries/korea-south.svg');
}

.img-icon.kosovo,
.flag-xk {
  background-image: url($images-path-static + 'countries/kosovo.svg');
}

.img-icon.kuwait,
.flag-kw {
  background-image: url($images-path-static + 'countries/kuwait.svg');
}

.img-icon.kyrgyzstan,
.flag-kg {
  background-image: url($images-path-static + 'countries/kyrgyzstan.svg');
}

.flag-la,
.img-icon.lao-people-s-democratic-republic {
  background-image: url($images-path-static + 'countries/lao-people-s-democratic-republic.svg');
}

.img-icon.latvia,
.img-icon.lv,
.flag-lv {
  background-image: url($images-path-static + 'countries/latvia.svg');
}

.img-icon.lebanon,
.flag-lb {
  background-image: url($images-path-static + 'countries/lebanon.svg');
}

.img-icon.lesotho,
.flag-ls {
  background-image: url($images-path-static + 'countries/lesotho.svg');
}

.img-icon.liberia,
.flag-lr {
  background-image: url($images-path-static + 'countries/liberia.svg');
}

.flag-ly,
.img-icon.libyan-arab-jamahiriya {
  background-image: url($images-path-static + 'countries/libyan-arab-jamahiriya.svg');
}

.img-icon.liechtenstein,
.flag-li {
  background-image: url($images-path-static + 'countries/liechtenstein.svg');
}

.img-icon.lithuania,
.flag-lt {
  background-image: url($images-path-static + 'countries/lithuania.svg');
}

.img-icon.luxembourg,
.flag-lu {
  background-image: url($images-path-static + 'countries/luxembourg.svg');
}

.img-icon.macau,
.flag-mo {
  background-image: url($images-path-static + 'countries/macau.svg');
}

.img-icon.macedonia,
.flag-mk {
  background-image: url($images-path-static + 'countries/macedonia.svg');
}

.img-icon.madagascar,
.flag-mg {
  background-image: url($images-path-static + 'countries/madagascar.svg');
}

.img-icon.malawi,
.flag-mw {
  background-image: url($images-path-static + 'countries/malawi.svg');
}

.img-icon.malaysia,
.flag-my {
  background-image: url($images-path-static + 'countries/malaysia.svg');
}

.img-icon.maldives,
.flag-mv {
  background-image: url($images-path-static + 'countries/maldives.svg');
}

.img-icon.mali,
.flag-ml {
  background-image: url($images-path-static + 'countries/mali.svg');
}

.img-icon.malta,
.flag-mt {
  background-image: url($images-path-static + 'countries/malta.svg');
}

.img-icon.marshall-islands,
.flag-mh {
  background-image: url($images-path-static + 'countries/marshall-islands.svg');
}

.img-icon.martinique,
.flag-mq {
  background-image: url($images-path-static + 'countries/martinique.svg');
}

.img-icon.mauritania,
.flag-mr {
  background-image: url($images-path-static + 'countries/mauritania.svg');
}

.img-icon.mauritius,
.flag-mu {
  background-image: url($images-path-static + 'countries/mauritius.svg');
}

.img-icon.mayotte,
.flag-yt {
  background-image: url($images-path-static + 'countries/mayotte.svg');
}

.img-icon.mexico,
.flag-mx {
  background-image: url($images-path-static + 'countries/mexico.svg');
}

.img-icon.moldova,
.flag-md {
  background-image: url($images-path-static + 'countries/moldova.svg');
}

.img-icon.monaco,
.flag-mc {
  background-image: url($images-path-static + 'countries/monaco.svg');
}

.img-icon.mongolia,
.flag-mn {
  background-image: url($images-path-static + 'countries/mongolia.svg');
}

.img-icon.montenegro,
.img-icon.me,
.flag-me {
  background-image: url($images-path-static + 'countries/montenegro.svg');
}

.img-icon.montserrat,
.flag-ms {
  background-image: url($images-path-static + 'countries/montserrat.svg');
}

.img-icon.morocco,
.flag-ma {
  background-image: url($images-path-static + 'countries/morocco.svg');
}

.img-icon.mozambique,
.flag-mz {
  background-image: url($images-path-static + 'countries/mozambique.svg');
}

.img-icon.myanmar,
.flag-mm {
  background-image: url($images-path-static + 'countries/myanmar.svg');
}

.flag-na,
.img-icon.nambia {
  background-image: url($images-path-static + 'countries/nambia.svg');
}

.img-icon.nauru,
.flag-nr {
  background-image: url($images-path-static + 'countries/nauru.svg');
}

.img-icon.nepal,
.flag-np {
  background-image: url($images-path-static + 'countries/nepal.svg');
}

.img-icon.netherlands,
.flag-nl,
.flag-sx {
  background-image: url($images-path-static + 'countries/netherlands.svg');
}

.img-icon.netherlands-antilles {
  background-image: url($images-path-static + 'countries/netherlands-antilles.svg');
}

.img-icon.new-caledonia,
.flag-nc {
  background-image: url($images-path-static + 'countries/new-caledonia.svg');
}

.img-icon.new-zealand,
.flag-nz {
  background-image: url($images-path-static + 'countries/new-zealand.svg');
}

.img-icon.nicaragua,
.flag-ni {
  background-image: url($images-path-static + 'countries/nicaragua.svg');
}

.img-icon.niger,
.flag-ne {
  background-image: url($images-path-static + 'countries/niger.svg');
}

.img-icon.nigeria,
.flag-ng {
  background-image: url($images-path-static + 'countries/nigeria.svg');
}

.img-icon.niue,
.flag-nu {
  background-image: url($images-path-static + 'countries/niue.svg');
}

.img-icon.norfolk-island,
.flag-nf {
  background-image: url($images-path-static + 'countries/norfolk-island.svg');
}

.img-icon.northern-ireland {
  background-image: url($images-path-static + 'countries/northern-ireland.svg');
}

.img-icon.northern-mariana-islands,
.flag-mp {
  background-image: url($images-path-static + 'countries/northern-mariana-islands.svg');
}

.img-icon.north-america {
  background-image: url($images-path-static + 'countries/north-america.svg');
}

.img-icon.norway,
.flag-no {
  background-image: url($images-path-static + 'countries/norway.svg');
}

.img-icon.oceania {
  background-image: url($images-path-static + 'countries/oceania.svg');
}

.img-icon.oman,
.flag-om {
  background-image: url($images-path-static + 'countries/oman.svg');
}

.img-icon.pakistan,
.flag-pk {
  background-image: url($images-path-static + 'countries/pakistan.svg');
}

.img-icon.palau,
.flag-pw {
  background-image: url($images-path-static + 'countries/palau.svg');
}

.flag-ps,
.img-icon.palestinian-territory-occupied {
  background-image: url($images-path-static + 'countries/palestinian-territory-occupied.svg');
}

.img-icon.panama,
.flag-pa {
  background-image: url($images-path-static + 'countries/panama.svg');
}

.img-icon.papua-new-guinea,
.flag-pg {
  background-image: url($images-path-static + 'countries/papua-new-guinea.svg');
}

.img-icon.paraguay,
.flag-py {
  background-image: url($images-path-static + 'countries/paraguay.svg');
}

.img-icon.peru,
.flag-pe {
  background-image: url($images-path-static + 'countries/peru.svg');
}

.img-icon.philippines,
.flag-ph {
  background-image: url($images-path-static + 'countries/philippines.svg');
}

.img-icon.pitcairn,
.flag-pn {
  background-image: url($images-path-static + 'countries/pitcairn.svg');
}

.img-icon.poland,
.img-icon.pl,
.flag-pl {
  background-image: url($images-path-static + 'countries/poland.svg');
}

.img-icon.portugal,
.flag-pt {
  background-image: url($images-path-static + 'countries/portugal.svg');
}

.img-icon.puerto-rico,
.flag-pr {
  background-image: url($images-path-static + 'countries/puerto-rico.svg');
}

.img-icon.qatar,
.flag-qa {
  background-image: url($images-path-static + 'countries/qatar.svg');
}

.img-icon.republic-of-ireland {
  background-image: url($images-path-static + 'countries/republic-of-ireland.svg');
}

.flag-re,
.img-icon.reunion {
  background-image: url($images-path-static + 'countries/reunion.svg');
}

.img-icon.romania,
.flag-ro {
  background-image: url($images-path-static + 'countries/romania.svg');
}

.img-icon.russia,
.img-icon.ru,
.flag-ru {
  background-image: url($images-path-static + 'countries/russia.svg');
}

.img-icon.rwanda,
.flag-rw {
  background-image: url($images-path-static + 'countries/rwanda.svg');
}

.flag-sh,
.img-icon.saint-helena-ascension-and-tristan-da-cunha {
  background-image: url($images-path-static + 'countries/saint-helena-ascension-and-tristan-da-cunha.svg');
}

.flag-bl,
.img-icon.saint-barthelemy {
  background-image: url($images-path-static + 'countries/saint-barthelemy.svg');
}

.img-icon.saint-kitts-and-nevis,
.flag-kn {
  background-image: url($images-path-static + 'countries/saint-kitts-and-nevis.svg');
}

.img-icon.saint-lucia,
.flag-lc {
  background-image: url($images-path-static + 'countries/saint-lucia.svg');
}

.img-icon.saint-martin-dutch-part {
  background-image: url($images-path-static + 'countries/saint-martin-dutch-part.svg');
}

.img-icon.saint-martin-french-part {
  background-image: url($images-path-static + 'countries/saint-martin-french-part.svg');
}

.img-icon.saint-pierre-and-miquelon,
.flag-pm,
.flag-mf {
  background-image: url($images-path-static + 'countries/saint-pierre-and-miquelon.svg');
}

.img-icon.saint-vincent-and-the-grenadines,
.flag-vc {
  background-image: url($images-path-static + 'countries/saint-vincent-and-the-grenadines.svg');
}

.img-icon.samoa,
.flag-ws {
  background-image: url($images-path-static + 'countries/samoa.svg');
}

.img-icon.san-marino,
.flag-sm {
  background-image: url($images-path-static + 'countries/san-marino.svg');
}

.flag-st,
.img-icon.sao-tome-and-principe {
  background-image: url($images-path-static + 'countries/sao-tome-and-principe.svg');
}

.img-icon.saudi-arabia,
.flag-sa {
  background-image: url($images-path-static + 'countries/saudi-arabia.svg');
}

.img-icon.scotland {
  background-image: url($images-path-static + 'countries/scotland.svg');
}

.img-icon.senegal,
.flag-sn {
  background-image: url($images-path-static + 'countries/senegal.svg');
}

.img-icon.serbia,
.img-icon.sr,
.flag-rs {
  background-image: url($images-path-static + 'countries/serbia.svg');
}

.img-icon.seychelles,
.flag-sc {
  background-image: url($images-path-static + 'countries/seychelles.svg');
}

.img-icon.sierra-leone,
.flag-sl {
  background-image: url($images-path-static + 'countries/sierra-leone.svg');
}

.img-icon.singapore,
.flag-sg {
  background-image: url($images-path-static + 'countries/singapore.svg');
}

.img-icon.slovakia,
.flag-sk {
  background-image: url($images-path-static + 'countries/slovakia.svg');
}

.img-icon.slovenia,
.flag-si {
  background-image: url($images-path-static + 'countries/slovenia.svg');
}

.img-icon.solomon-islands,
.flag-sb {
  background-image: url($images-path-static + 'countries/solomon-islands.svg');
}

.img-icon.somalia,
.flag-so {
  background-image: url($images-path-static + 'countries/somalia.svg');
}

.img-icon.south-africa,
.flag-za {
  background-image: url($images-path-static + 'countries/south-africa.svg');
}

.img-icon.south-america {
  background-image: url($images-path-static + 'countries/south-america.svg');
}

.img-icon.south-sudan,
.flag-ss {
  background-image: url($images-path-static + 'countries/south-sudan.svg');
}

.img-icon.spain,
.flag-es {
  background-image: url($images-path-static + 'countries/spain.svg');
}

.img-icon.sri-lanka,
.flag-lk {
  background-image: url($images-path-static + 'countries/sri-lanka.svg');
}

.img-icon.state-union-of-serbia-and-montenegro {
  background-image: url($images-path-static + 'countries/state-union-of-serbia-and-montenegro.svg');
}

.img-icon.sudan,
.flag-sd {
  background-image: url($images-path-static + 'countries/sudan.svg');
}

.img-icon.suriname,
.flag-sr {
  background-image: url($images-path-static + 'countries/suriname.svg');
}

.img-icon.svalbard-and-jan-mayen,
.flag-sj {
  background-image: url($images-path-static + 'countries/svalbard-and-jan-mayen.svg');
}

.img-icon.swaziland,
.flag-sz {
  background-image: url($images-path-static + 'countries/swaziland.svg');
}

.img-icon.sweden,
.flag-se {
  background-image: url($images-path-static + 'countries/sweden.svg');
}

.img-icon.switzerland,
.flag-ch {
  background-image: url($images-path-static + 'countries/switzerland.svg');
}

.img-icon.syria,
.flag-sy {
  background-image: url($images-path-static + 'countries/syria.svg');
}

.img-icon.tristan-da-cunha,
.flag-ta {
  background-image: url($images-path-static + 'countries/australia.svg');
}

.img-icon.taiwan,
.flag-tw {
  background-image: url($images-path-static + 'countries/taiwan.svg');
}

.img-icon.tajikistan,
.flag-tj {
  background-image: url($images-path-static + 'countries/tajikistan.svg');
}

.img-icon.thailand,
.flag-th {
  background-image: url($images-path-static + 'countries/thailand.svg');
}

.flag-cg,
.img-icon.the-democratic-republic-of-the-congo {
  background-image: url($images-path-static + 'countries/the-democratic-republic-of-the-congo.svg');
}

.img-icon.timor-leste,
.flag-tl {
  background-image: url($images-path-static + 'countries/timor-leste.svg');
}

.img-icon.togo,
.flag-tg {
  background-image: url($images-path-static + 'countries/togo.svg');
}

.img-icon.tokelau,
.flag-tk {
  background-image: url($images-path-static + 'countries/tokelau.svg');
}

.img-icon.tonga,
.flag-to {
  background-image: url($images-path-static + 'countries/tonga.svg');
}

.img-icon.trinidad-and-tobago,
.flag-tt {
  background-image: url($images-path-static + 'countries/trinidad-and-tobago.svg');
}

.img-icon.tunisia,
.flag-tn {
  background-image: url($images-path-static + 'countries/tunisia.svg');
}

.img-icon.turkey,
.flag-tr {
  background-image: url($images-path-static + 'countries/turkey.svg');
}

.img-icon.turkmenistan,
.flag-tm {
  background-image: url($images-path-static + 'countries/turkmenistan.svg');
}

.img-icon.turks-and-caicos-islands,
.flag-tc {
  background-image: url($images-path-static + 'countries/turks-and-caicos-islands.svg');
}

.img-icon.tuvalu,
.flag-tv {
  background-image: url($images-path-static + 'countries/tuvalu.svg');
}

.img-icon.uganda,
.flag-ug {
  background-image: url($images-path-static + 'countries/uganda.svg');
}

.img-icon.ukraine,
.img-icon.uk,
.flag-ua {
  background-image: url($images-path-static + 'countries/ukraine.svg');
}

.img-icon.united-arab-emirates,
.flag-ae {
  background-image: url($images-path-static + 'countries/united-arab-emirates.svg');
}

.img-icon.united-kingdom,
.flag-gb,
.flag-en {
  background-image: url($images-path-static + 'countries/united-kingdom.svg');
}

.flag-tz,
.img-icon.united-republic-of-tanzania {
  background-image: url($images-path-static + 'countries/united-republic-of-tanzania.svg');
}

.img-icon.united-states-minor-outlying-islands,
.flag-um {
  background-image: url($images-path-static + 'countries/united-states-minor-outlying-islands.svg');
}

.img-icon.uruguay,
.flag-uy {
  background-image: url($images-path-static + 'countries/uruguay.svg');
}

.flag-us,
.img-icon.usa {
  background-image: url($images-path-static + 'countries/usa.svg');
}

.img-icon.uzbekistan,
.flag-uz {
  background-image: url($images-path-static + 'countries/uzbekistan.svg');
}

.img-icon.vanuatu,
.flag-vu {
  background-image: url($images-path-static + 'countries/vanuatu.svg');
}

.img-icon.venezuela,
.flag-ve {
  background-image: url($images-path-static + 'countries/venezuela.svg');
}

.img-icon.vietnam,
.flag-vn {
  background-image: url($images-path-static + 'countries/vietnam.svg');
}

.flag-vg,
.img-icon.virgin-islands-british {
  background-image: url($images-path-static + 'countries/virgin-islands-british.svg');
}

.flag-vi,
.img-icon.virgin-islands-u-s {
  background-image: url($images-path-static + 'countries/virgin-islands-u-s.svg');
}

.img-icon.wales {
  background-image: url($images-path-static + 'countries/wales.svg');
}

.img-icon.wallis-and-futuna,
.flag-wf {
  background-image: url($images-path-static + 'countries/wallis-and-futuna.svg');
}

.img-icon.western-sahara,
.flag-eh {
  background-image: url($images-path-static + 'countries/western-sahara.svg');
}

.img-icon.west-germany {
  background-image: url($images-path-static + 'countries/west-germany.svg');
}

.img-icon.world,
.img-icon.santa-rita,
.img-icon.srl-matches,
.img-icon.concepcion {
  background-image: url($images-path-static + 'countries/world.svg');
}

.img-icon.yemen,
.flag-ye {
  background-image: url($images-path-static + 'countries/yemen.svg');
}

.img-icon.zaire {
  background-image: url($images-path-static + 'countries/zaire.svg');
}

.img-icon.zambia,
.flag-zm {
  background-image: url($images-path-static + 'countries/zambia.svg');
}

.img-icon.zimbabwe,
.flag-zw {
  background-image: url($images-path-static + 'countries/zimbabwe.svg');
}
