@font-face {
  font-display: swap; 
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('^assets/fonts/open-sans-v35-latin-300.woff2') format('woff2');
}

@font-face {
  font-display: swap; 
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: url('^assets/fonts/open-sans-v35-latin-300italic.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('^assets/fonts/open-sans-v35-latin-regular.woff2') format('woff2'); 
}

@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('^assets/fonts/open-sans-v35-latin-italic.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  src: url('^assets/fonts/open-sans-v35-latin-500.woff2') format('woff2'); 
}

@font-face {
  font-display: swap; 
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 500;
  src: url('^assets/fonts/open-sans-v35-latin-500italic.woff2') format('woff2'); 
}

@font-face {
  font-display: swap; 
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('^assets/fonts/open-sans-v35-latin-600.woff2') format('woff2');
}

@font-face {
  font-display: swap; 
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: url('^assets/fonts/open-sans-v35-latin-600italic.woff2') format('woff2'); 
}

@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('^assets/fonts/open-sans-v35-latin-700.woff2') format('woff2'); 
}

@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: url('^assets/fonts/open-sans-v35-latin-700italic.woff2') format('woff2'); 
}

@font-face {
  font-display: swap; 
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url('^assets/fonts/open-sans-v35-latin-800.woff2') format('woff2'); 
}

@font-face {
  font-display: swap; 
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 800;
  src: url('^assets/fonts/open-sans-v35-latin-800italic.woff2') format('woff2');
}
