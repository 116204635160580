.dropdown {
  display: block;
  position: relative;
  width: 190px;
  height: 41px;
  transition: all 0.3s ease;
  user-select: none;
  cursor: pointer;

  &.is-active {
    .dropdown__list {
      display: block;
    }
  }

  &__selected {
    display: flex;
    align-items: center;
    height: 100%;
    background-color: #313137;
    border-radius: 20px;

    li {
      display: flex;
      position: relative;
      align-items: center;
      width: 100%;
      padding: var(--space-1) 0 var(--space-1) var(--space-3);
      font-size: 14px;
      font-weight: 600;
      color: var(--white);
    }

    .icon-cp {
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
      width: 17px;

      path {
        fill: #9b9b9b;
      }
    }
  }

  &__list {
    display: none;
    position: absolute;
    z-index: 20;
    overflow-y: auto;
    max-height: 220px;
    bottom: -2px;
    left: 0;
    transform: translateY(calc(100%));
    width: 100%;
    padding: var(--space-2) 0;
    background-color: #313137;
    border-radius: 20px;

    li {
      margin: 0;

      a {
        display: flex;
        align-items: center;
        height: 41px;
        padding: var(--space-3) var(--space-5) var(--space-3) var(--space-3);
        font-size: 14px;
        font-weight: 600;
        color: var(--white);
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &--outline {
    .dropdown__selected {
      background-color: var(--bg-body);
      border: 2px solid #313137;

      .icon-cp path {
        fill: #313137;
      }
    }
  }

  .flag-icon {
    margin-right: var(--space-3);
  }
}

.dropdown--sidebar {
  width: 100%;
  height: 40px;

  &.dropdown--outline {
    .dropdown__selected {
      .icon-cp path {
        width: 13px;
        height: 7px;
        fill: #79798B;
      }
    }
  }

  .dropdown__selected {
    border-radius: var(--banner-radius, var(--radius-1));
    background-color: var(--dropdown--sidebar-bg, var(--bg-primary));

    span {
      font-size: 12px;
      font-weight: 700;
      letter-spacing: -0.02em;
    }
  }

  .flag-icon {
    width: 16px;
    height: 16px;
    margin-right: var(--space-2);
  }

  .dropdown__list {
    border-radius: var(--banner-radius, var(--radius-1));

    a {
      font-size: 12px;
      font-weight: 700;
      letter-spacing: -0.02em;
      border-radius: var(--dropdown-list-radius, var(--radius-1));
    }
  }
}
