.kumulos {
  &-background-mask {
    z-index: 1000 !important;
    .modal-open & {
      display: none;
    }
    &-blur > * {
      filter: unset !important;
    }
  }

  body & {
    &-banner {
      &-container {
        overflow: hidden;
        left: 50%;
        transform: translateX(-50%);
        margin-top: var(--space-3);
        padding: var(--space-6) var(--space-3) var(--space-3);
        width: calc(100vw - var(--space-6));
        max-width: calc(800px - var(--space-6));
        border-radius: var(--radius-m);
        flex-flow: wrap !important;

        &.kumulos-prompt-position-top {
          animation: unset;
        }
      }

      &-content {
        justify-content: center;
      }

      &-icon {
        display: flex;
        margin-right: var(--space-3);
        width: 56px;
        height: 56px;
        min-width: 56px;
        border-radius: 50%;
        background: rgba(251, 205, 0, 0.15);
        background-image: unset !important;
        &::after {
          display: inline-block;
          margin: auto;
          width: 24px;
          height: 24px;
          background-image: url('#{$images-path-static}other/optimove/bell.svg');
          content: ' ';
        }
      }

      &-body {
        margin-top: var(--space-2);
        flex-grow: unset;
        color: var(--neutral-40);
        font-size: 14px;
        font-weight: 600;
        line-height: 150%;
        letter-spacing: -0.02em;
      }

      &-header h1 {
        font-size: 16px;
        font-weight: 800;
        line-height: 150%;
        letter-spacing: -0.02em;
      }

      &-actions {
        margin-top: var(--space-6);
        width: 100% !important;
      }
    }

    &-action-button {
      margin-left: 0;
      padding: 0;
      width: 100%;
      height: 40px;
      border-radius: 12px;
      font-size: 14px;
      font-weight: 700;
      line-height: 100%;
      letter-spacing: -0.02em;

      &:not(:first-child) {
        margin-left: var(--space-3);
      }
    }
  }
}
