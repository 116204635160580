.footer {
  display: block;
  margin: 0 auto;
  max-width: var(--container-max-width);

  &__inner {
    padding: 40px var(--container-side-padding) 0 var(--container-side-padding);
  }

  &__seo {
    margin: 0 0 25px;
    font-size: 12px;
    font-weight: 400;
    color: var(--seo-color, #58585b);

    &-col {
      margin-bottom: 20px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    &-title {
      margin: 10px 0;
      font-size: 13px;
      font-weight: 700;
      color: #818181;
    }

    a {
      color: var(--f-grey-27);
    }

    ul {
      li {
        margin-bottom: var(--space-3);
        position: relative;
        padding-left: calc(7px + var(--space-3));
        color: var(--f-white-2);
        font-size: 12px;
        font-weight: 600;
        line-height: 1.8;

        &::before {
          content: '';
          position: absolute;
          top: 6px;
          left: 0;
          transform: rotate(45deg);
          width: 7px;
          height: 7px;
          background-color: var(--marker-color, var(--basic-color));
          border-top-left-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    ol {
      li {
        color: var(--f-grey-21);

        &::before {
          counter-increment: list;
          content: counters(list, '.') '.  ';
          font-weight: 800;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    ol,
    ul {
      margin: var(--space-3) 0;

      li {
        font-size: 12px;
        font-weight: 600;
        line-height: 1.8;
        margin-bottom: var(--space-3);
      }
    }

    p {
      margin-bottom: 20px;
    }

    &-more {
      display: none;

      &.is-active {
        display: block;
      }
    }

    &-buttons {
      color: #58585b;
      font-weight: 700;
      font-size: 13px;
    }

    &-btn {
      font-size: 13px;
      font-weight: 700;
      color: #58585b;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
  }

  &__menu {
    display: flex;
    margin: 0 0 40px;
    padding-top: 20px;
    position: relative;
    border-bottom: 2px solid var(--line-primary);

    &-panel {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      position: absolute;
      z-index: 2;
      right: 0;
      bottom: 0;
      transform: translateY(50%);
      padding-left: 10px;
      background-color: var(--bg-body);
    }

    .dropdown {
      flex: 1 0 0;
      max-width: 190px;
      width: 100%;
      height: 38px;
    }

    .btn {
      flex: 0 0 38px;
      margin-right: 10px;
    }

    .btn-facebook {
      background-color: var(--bg-blue-facebook);
    }

    .btn-twitter {
      background-color: var(--bg-twitter);
    }

    .btn-instagram {
      background-color: var(--bg-blue-instagram);

      path {
        fill: var(--white);
      }
    }

    .btn-telegram {
      background-color: var(--bg-blue-telegram);
    }
  }

  &__brands {
    margin-bottom: 20px;
    opacity: 0.75;

    &-title {
      text-transform: uppercase;
      font-style: italic;
      font-weight: 700;
      font-size: 14px;
      margin-bottom: var(--space-4);
    }

    &-list {
    }

    &-list-box {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__brand {
    display: flex;
    align-items: center;
    margin: 0 15px 25px 0;
  }

  &__license {
    margin-bottom: 30px;
    overflow-wrap: break-word;
    font-size: 10px;
    font-weight: 400;
    color: #58585b;

    a {
      color: #58585b;
    }
  }

  &__bottom {
    margin-bottom: var(--ds-space-6);
    &-icons {
      display: flex;
      align-items: center;
      margin-bottom: var(--ds-space-3);
      span {
        margin-right: var(--space-2);
        color: var(--reserved-color, #58585b);
      }
    }

    &-icon {
      display: flex;
      height: 100%;
      justify-content: center;
    }

    &-reserved {
      font-size: 12px;
      font-weight: 400;
      color: var(--reserved-color, #58585b);

      span {
        font-weight: 800;
        color: var(--white);
      }
    }
  }
}
