:root {
  // APP
  --container-max-width: 800px;
  --container-side-padding: 10px;

  // HEADER
  --header-height: 90px;

  /* stylelint-disable-line -- need this to top header works */
  --top-space-height: 0px;

  // BOTTOM NAV
  --bottom-nav-height: 69px;

  // GAME-ITEM
  --game-item-image-height: 168.5px;
  --game-item-image-height-lc: 168.5px;
  --game-item-slide-height: 251.5px;
  --game-item-slide-width: 114px;
  --game-item-slide-width-lc: 246px;
  --slide-width-promotion: 350px;
  --slide-width-tournament: 350px;

  // OPEN GAME
  --header-game-height: 68px;
  --snack-game-height: 66px;
  --game-lottery-height-inactive: 5px;
  --game-lottery-height-active: calc(var(--header-game-height));
  --game-lottery-box-height: calc(100% - 6px);
  --game-lottery-box-width: 90px;
  --game-menu-width: 300px;

  //BORDER RADIUS
  --radius-primary: 20px;

  //IOS SAFE AREA
  --safe-area-top: env(safe-area-inset-top);
  --safe-area-bottom: env(safe-area-inset-bottom);
  --safe-area-left: env(safe-area-inset-left);
  --safe-area-right: env(safe-area-inset-right);
}
