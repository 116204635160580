bcf-icon,
.icon-cp {
  display: flex;

  svg {
    width: 100%;
    height: 100%;
  }

  @include breakpoint-min(1200px) {
    circle,
    path {
      transition: all 0.3s ease;
    }
  }
}

.icon-eye,
.icon-eye-slashed {
  path {
    fill: var(--neutral-60);
  }
}

.icon {
  &-pink path {
    fill: var(--pink);
  }

  &-black path {
    fill: var(--black);
  }
}

.icon {
  &-user {
    width: 16px;
    height: 18px;
  }

  &-info {
    width: 5px;
    height: 23px;
    @include breakpoint-min(800px) {
      width: 32px;
      height: 27px;
    }
  }

  &-vip-user {
    width: 16px;
    height: 7px;
  }

  &-search {
    width: 19px;
    height: 19px;
  }

  &-cherry {
    width: 26px;
    height: 21px;
  }

  &-live-casino {
    width: 23px;
    height: 23px;
  }

  &-table-games {
    width: 27px;
    height: 21px;
  }

  &-jackpots {
    width: 25px;
    height: 24px;
  }

  &-feed {
    width: 28px;
    height: 22px;
  }

  &-tournaments {
    width: 22px;
    height: 19px;
  }

  &-promotions {
    width: 22px;
    height: 24px;
  }

  &-message {
    width: 21px;
    height: 21px;
  }

  &-mobile-app {
    width: 18px;
    height: 22px;
  }

  &-android-logo {
    width: 45px;
    height: 57px;
  }

  &-balance {
    width: 23px;
    height: 28px;
  }

  &-inbox {
    width: 23px;
    height: 19px;
  }

  &-favorites {
    width: 21px;
    height: 18px;
  }

  &-favorites-circle {
    width: 24px;
    height: 24px;
  }

  &-heart {
    width: 16px;
    height: 16px;
  }

  &-trophy-tournament {
    width: 25px;
    height: 20px;
  }

  &-bell {
    width: 18px;
    height: 20px;
  }

  &-settings {
    width: 18px;
    height: 18px;
  }

  &-share {
    width: 18px;
    height: 18px;
  }

  &-dots-menu {
    width: 4px;
    height: 20px;
  }

  &-latvijas-republikas {
    width: 30px;
    height: 26px;
  }

  &-secured18 {
    width: 28px;
    height: 28px;
  }

  &-mga {
    width: 104px;
    height: 20px;
  }

  &-gambling-commision {
    width: 70px;
    height: 20px;
  }

  &-casino-meister {
    width: 72px;
    height: 18px;
  }

  &-game-lottery-money {
    width: 23px;
    height: 16px;
  }

  &-game-lottery-bonus {
    width: 25px;
    height: 15px;
  }

  &-game-lottery-freespin {
    width: 25px;
    height: 15px;
  }

  &-game-lottery-points {
    width: 24px;
    height: 15px;
  }

  &-envelope {
    width: 20px;
    height: 20px;
  }

  &-file-pdf {
    width: 11px;
    height: 15px;
  }

  &-arrow-back {
    width: 20px;
    height: 20px;
  }

  &-arrow-left {
    width: 20px;
    height: 20px;
  }

  &-arrow-right {
    width: 20px;
    height: 20px;
  }

  &-notifications {
    width: 19px;
    height: 19px;
  }

  &-link {
    width: 18px;
    height: 18px;
  }

  &-facebook {
    width: 18px;
    height: 18px;
  }

  &-twitter {
    width: 17px;
    height: 17px;
  }

  &-instagram {
    width: 24px;
    height: 24px;
  }

  &-telegram {
    width: 20px;
    height: 20px;
  }

  &-plus {
    width: 12px;
    height: 12px;
  }

  &-eparaksts {
    width: 131px;
    height: 20px;
  }

  &-smartid {
    width: 150px;
    height: 20px;
  }

  &-vefriga {
    width: 55px;
    height: 18px;
  }

  &-kspro-fighters-league {
    width: 46px;
    height: 50px;
  }

  &-lucky-punch {
    width: 89px;
    height: 40px;
  }

  &-latvia-drift {
    width: 84px;
  }

  &-leevon-ppk {
    width: 52px;
    height: 68px;
  }

  &-riga-fc {
    height: 68px;
    width: 68px;
  }

  &-fk-auda {
    height: 68px;
    width: 59px;
  }

  &-371 {
    height: 40px;
    width: 72px;
  }

  // PAYMENT METHODS
  &-mastercard {
    width: 27px;
    height: 21px;
  }

  &-maestro {
    width: 25px;
    height: 19px;
  }

  &-visa {
    width: 54px;
    height: 18px;
  }

  &-astro-pay {
    width: 75px;
    height: 20px;
  }

  &-jeton {
    width: 79px;
    height: 20px;
  }

  &-ecopayz {
    width: 54px;
    height: 18px;
  }

  &-neteller {
    width: 112px;
    height: 20px;
  }

  &-paysafecard {
    width: 140px;
    height: 24px;
  }

  &-skrill {
    width: 57px;
    height: 20px;
  }

  &-skrill-tap {
    width: 96px;
    height: 20px;
  }

  &-much-better {
    width: 90px;
    height: 20px;
  }

  &-paypal {
    width: 90px;
    height: 22px;
  }

  &-dotpay-cards {
    width: 110px;
    height: 22px;
  }

  // PROVIDERS
  &-bf-games {
    width: 25px;
    height: 20px;
  }

  &-play-n-go {
    width: 69px;
    height: 19px;
  }

  &-netent {
    width: 53px;
    height: 20px;
  }

  &-pragmatic-play {
    width: 42px;
    height: 19px;
  }

  &-wazdan {
    width: 109px;
    height: 20px;
  }

  &-synot {
    width: 97px;
    height: 19px;
  }

  &-red-tiger {
    width: 119px;
    height: 19px;
  }

  &-gamevy {
    width: 84px;
    height: 19px;
  }

  &-thunderkick {
    width: 114px;
    height: 20px;
  }

  &-skywind-group {
    width: 89px;
    height: 19px;
  }

  &-blueprint-gaming {
    width: 67px;
    height: 22px;
  }
}

.right-menu,
.side-menu {
  .icon-state-toggle:hover,
  .icon-state-toggle.is-active {
    .icon-promo-codes,
    .icon-user {
      path {
        fill: #c7a100;
      }
      path:nth-child(2) {
        fill: #fbcd00;
      }
    }

    .cls-1-favoritescircle {
      fill: #c7a100;
    }

    .cls-1-inbox {
      fill: #c7a100;
    }

    .cls-2-inbox {
      fill: #fbcd00;
    }
    .cls-1-casino {
      fill: #c7a100;
    }
    .cls-2-casino {
      fill: #fbcb00;
    }
    .cls-2-sports {
      fill: #fbcb00;
    }
    .cls-1-cherry {
      fill: #c7a100;
    }

    .cls-2-cherry {
      fill: #fbcb00;
    }

    .cls-1-livecasino {
      fill: #c7a100;
    }

    .cls-2-livecasino {
      fill: #fbcb00;
    }

    .cls-3-livecasino {
      fill: #c7a100;
    }

    .cls-4-livecasino {
      fill: #fbcb00;
    }

    .cls-1-tablegames {
      fill: #c7a100;
    }

    .cls-2-tablegames {
      fill: #fbcb00;
    }

    .cls-1-jackpots {
      fill: #c7a100;
    }

    .cls-2-jackpots {
      fill: #fbcb00;
    }

    .cls-1-feed {
      fill: #c7a100;
    }

    .cls-2-feed {
      fill: #fbcb00;
    }

    .cls-3-feed {
      fill: #c7a100;
    }

    .cls-1-search {
      fill: #c7a100;
    }

    .cls-2-search {
      fill: #fbcb00;
    }

    .cls-1-promotions {
      fill: #c7a100;
    }

    .cls-2-promotions {
      fill: #fbcd00;
    }

    .cls-3-promotions {
      fill: #c7a100;
    }

    .cls-4-promotions {
      fill: #fbcd00;
    }

    .cls-5-promotions {
      fill: #c7a100;
    }

    .cls-1-mobileapp {
      fill: #c7a100;
    }

    .cls-2-mobileapp {
      fill: #fbcd00;
    }

    .cls-1-message {
      fill: #c7a100;
    }

    .cls-2-message {
      fill: #fbcd00;
    }
  }
}

.icon-state-toggle.is-active {
  .cls-1-cherry {
    fill: #fff;
  }

  .cls-2-cherry {
    fill: #fb0000;
  }

  .cls-1-livecasino {
    fill: #fed796;
  }

  .cls-2-livecasino {
    fill: #f4c601;
  }

  .cls-3-livecasino {
    fill: #fff;
  }

  .cls-4-livecasino {
    fill: #f01101;
  }

  .cls-1-tablegames {
    fill: #fff;
  }

  .cls-2-tablegames {
    fill: #f01101;
  }

  .cls-1-jackpots {
    fill: #fbcb00;
  }

  .cls-2-jackpots {
    fill: #fff;
  }

  .cls-1-feed {
    fill: #fff;
  }

  .cls-2-feed {
    fill: #00b372;
  }

  .cls-3-feed {
    fill: #fbcb00;
  }

  .cls-1-search {
    fill: #fbcb00;
  }

  .cls-2-search {
    fill: #fbcb00;
  }

  .cls-1-promotions {
    fill: #fed796;
  }

  .cls-2-promotions {
    fill: #f4c601;
  }

  .cls-3-promotions {
    fill: #f4c601;
  }

  .cls-4-promotions {
    fill: #fed796;
  }

  .cls-5-promotions {
    fill: #fed796;
  }

  .cls-1-mobileapp {
    fill: #ccc;
  }

  .cls-2-mobileapp {
    fill: #f5bc8f;
  }

  .cls-1-message {
    fill: #456e75;
  }

  .cls-2-message {
    fill: #96ebff;
  }
}
