:root {
  // PRIMARY COLORS
  --bg-body: #110f11;
  --bg-primary: #313137;
  --bg-brand: #fbcb00;
  --brand-50: #ffd72e;
  --black: #000;
  --white: #fff;
  --bg-green: #00b372;
  --bg-red: #d30404;
  --bg-red-2: #f00000;
  --pink: #fd008f;

  // RANKING COLORS
  --gold: #fbcb00;
  --silver: #b8b8b8;
  --bronze: #d96829;

  //active link color
  --active-color: #44380d;

  // FONT COLORS
  --f-brand: #fbcb00;
  --f-grey-1: #b7b7c2;
  --f-grey-2: #484848;
  --f-grey-3: #999;
  --f-grey-4: #58585b;
  --f-grey-5: #636363;
  --f-grey-6: #e1e1e1;
  --f-grey-7: #b0b0b0;
  --f-grey-8: #d6d6d6;
  --f-grey-9: #e1e1e1;
  --f-grey-10: #818181;
  --f-grey-11: #a5a5a5;
  --f-grey-12: #8d8d92;
  --f-grey-13: #aaa;
  --f-grey-14: #313137;
  --f-grey-15: #434343;
  --f-grey-16: #56565a;
  --f-grey-17: #d2d2d2;
  --f-grey-18: #a3a3a3;
  --f-grey-19: #1b1b1f;
  --f-grey-20: #222227;
  --f-grey-21: #b0b0ba;
  --f-grey-22: #9595a2;
  --f-grey-23: #585860;
  --f-grey-24: #58585b;
  --f-grey-25: #616170;
  --f-grey-27: #cecece;
  --f-black: #000;
  --f-black-2: #110f11;
  --f-white: #fff;
  --f-white-2: #cecece;

  // BRANDS COLORS
  --bg-blue-facebook: #1976d2;
  --bg-twitter: #000;
  --bg-blue-instagram: #5258cf;
  --bg-blue-telegram: #28a8e8;
  --color-message-icon-1: #456e75;
  --color-message-icon-2: #96ebff;
  --neutral-dark-0: #16161a;
  --neutral-dark-2: #323239;
  --neutral-dark-3: #4a4a55;

  // BORDER COLORS
  --line-primary: #313137;
  --line-border: #b7b7c2;
  --line-border-2: #5d5d67;

  // STATE COLORS
  --color-place: var(--white);
  --color-status: var(--white);

  // RANKING COLOR CHANGES
  .first-place {
    --color-place: var(--gold);
  }

  .second-place {
    --color-place: var(--silver);
  }

  .third-place {
    --color-place: var(--bronze);
  }

  // STATUS COLOR CHANGES
  .is-enabled {
    --color-status: var(--f-brand);
  }

  //TO REFACTOR IN FUTURE

  //COLORS
  --body-bg: #110f11;
  --color-1: #fff;
  --basic-color: #fbcb00;
  --link-active: #44380d;
  --bg-install: #00b372;
  --install-color: #fff;

  //BORDER
  --border-1: 2px solid #313137;

  //Radiuses
  --radius-1: 15px;
}
