.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    outline: none;
    font-size: 14px;
    font-family: inherit;
    font-weight: 700;
    letter-spacing: -0.05em;
    border: 2px solid;
    border-radius: var(--radius-primary);

    &-basic {
        color: var(--black);
        background-color: var(--bg-brand);
        border-color: var(--bg-brand);
    }

    &-primary {
        color: var(--white);
        background-color: var(--bg-primary);
        border-color: var(--bg-primary);
    }

    &-primary--outline {
        color: var(--white);
        background-color: var(--bg-body);
        border-color: var(--bg-primary);
    }

    &-green {
        color: var(--white);
        background-color: var(--bg-green);
        border-color: var(--bg-green);
    }

    &-icon {
        position: relative;
        height: 38px;
        width: 38px;
        padding: 0;
        border: none;
        background-color: transparent;

        &__counter {
            position: absolute;
            top: -5px;
            right: -6px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background-color: #99999d;
            text-align: center;
            font-size: 12px;
            font-weight: 600;
            color: var(--white);

            &--bottom {
                top: unset;
                bottom: 2px;
                right: -4px;
            }

            &.is-active {
                background-color: var(--bg-red);
            }

            .icon-envelope {
                width: 10px;
                height: 10px;
            }
        }
    }
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    background: transparent;
    font-family: "Open Sans"
}

.main-button {
    height: 38px;
    width: 100px;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 700;
    border-radius: 20px;
}

.check-all {
    min-width: 180px;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 700;
    color: var(--white);
    background-color: #313137;
    border-radius: 25px;
}