.side-menu {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  &__container {
    position: relative;
    height: 100%;
  }

  &.is-active {
    .side-menu__inner {
      transform: translateX(0);
    }

    .side-menu__message {
      opacity: 1;
    }
  }

  &__inner {
    z-index: 5;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 320px;
    height: 100%;
    padding-top: calc(20px + var(--safe-area-top));
    margin-top: var(--top-space-height);
    background-color: var(--menu-bg, var(--f-grey-19));
    transform: translateX(calc(-100% - 100px));
    transition: all 0.3s ease;

    &-shadow {
      z-index: 3;
      position: absolute;
      top: 0;
      right: 0;
      width: 100vw;
      height: 100%;
      background-color: var(--menu-bg, var(--black));
      animation: fadeIn 0.3s forwards;

      @keyframes fadeIn {
        from {
          opacity: 0;
        }

        to {
          opacity: 0.7;
        }
      }
    }
  }

  &__banner {
    padding: var(--space-3);
    margin-bottom: var(--space-5);
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: var(--banner-radius, var(--radius-1));
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    span {
      display: block;
      letter-spacing: -0.05em;
    }

    &-title {
      padding-bottom: var(--space-1);
      font-size: 14px;
      font-weight: 800;
      line-height: 14px;
    }

    &-text {
      font-size: 12px;
      font-weight: 600;
    }
  }

  &__pwa-container {
    display: flex;
    justify-content: space-between;
    max-width: 237px;
    width: 100%;
    padding: var(--space-3);
    background-color: var(--menu-bg, var(--f-grey-20));
    border-radius: var(--pwa-container-radius, var(--radius-1));

    a {
      max-width: 161px;
      width: 100%;
      height: 40px;
      color: var(--pwa-install-color, var(--install-color));
      background-color: var(--pwa-install-bg, var(--bg-install));
      font-weight: 700;
      font-size: 14px;
      border-radius: var(--pwa-container-link-radius, var(--radius-1));
      border-color: transparent;
    }
  }

  &__pwa-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-left: var(--pwa-icon-container-m, var(--space-3));
    background-color: var(--pwa-icon-container-bg, var(--bg-primary));
    border-radius: var(--pwa-icon-container-radius, var(--radius-1));

    .icon-cp {
      width: 18px;
      height: 18px;

      path {
        fill: var(--pwa-icon-container-bg, var(--f-grey-21));
      }
    }
  }

  &__content {
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    margin-top: 25px;
    padding: var(--space-4) var(--space-5) 90px var(--space-5);
  }

  &__pwa {
    position: relative;
    display: flex;
    align-items: flex-end;

    .icon-cp {
      display: flex;
    }

    .btn {
      min-width: 140px;
      height: 39px;
      margin-left: 12px;
      padding: 0 var(--space-7);
      --bg: var(--primary-green);
      --color: var(--white);
      font-size: 14px;
      font-weight: 600;
    }
  }

  &__links {
    padding: var(--space-5) 0 var(--space-6);
    border-bottom: 2px solid var(--links-border, var(--bg-primary));
  }

  &__links--without-icons {
    padding: var(--space-8) 0 0 0;
    border-bottom: none;

    .side-menu__category-item {
      span {
        padding-left: 0;
      }
    }
  }

  &__category-item {
    margin-bottom: var(--category-item-m, var(--space-5));
  }

  &__container-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: var(--pwa-icon-container-bg, var(--bg-primary));
    border-radius: var(--container-icon-radius, var(--radius-1));
  }

  &__category-link {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    color: var(--category-color, var(--white));
    font-size: 14px;
    font-weight: bold;
    line-height: 19px;

    &--secondary {
      font-size: 13px;
    }

    &-name {
      flex: 1;
      padding-left: var(--space-4);
    }

    &.is-active {
      .side-menu__category-link-name {
        color: var(--category-color-active, var(--bg-brand));
      }

      .side-menu__container-icon {
        background-color: var(--category-bg-active, var(--active-color));
      }
    }

    &-info {
      flex: 0 0 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 20px;
      font-size: 10px;
      font-weight: 700;
      background-color: var(--category-info, var(--bg-red-2));
      border-radius: var(--info-radius, var(--radius-1));
    }
  }

  &__odds-type-switcher {
    margin-bottom: 40px;
    margin-left: -4.5px;
  }

  &__list {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 40px;
    }
  }

  &__link-item {
    margin-bottom: 18px;

    a {
      color: var(--link-item-color, var(--f-grey-22));
      font-size: 13px;
      font-weight: 600;
    }
  }

  .icon-cp {
    width: 18px;
    height: 18px;
  }

  &__message {
    z-index: 10;
    position: absolute;
    bottom: calc(0px + var(--safe-area-bottom, 0px));
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    margin: 0 10px 90px 0;
    background-color: var(--btn-message-bg, var(--bg-primary));
    border-radius: 50px;
    opacity: 0;
    transition: all 0.3s ease;

    .icon-comments {
      width: 25px;
      height: 25px;

      .cls-1-message {
        fill: var(--btn-message-svg-bg2, var(--f-grey-23));
      }

      .cls-2-message {
        fill: var(--btn-message-svg-bg1, #b9b9bd);
      }
    }
  }

  @include breakpoint-max(359px) {
    &__message {
      width: 60px;
      height: 60px;
      margin: 0 5px 30px 0;

      .icon-comments {
        width: 22px;
        height: 22px;
      }
    }
  }
}
