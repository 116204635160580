.headline-primary {
    color: var(--white);
    font-size: 18px;
    font-weight: 800;
    letter-spacing: -0.05em;
}

.headline-secondary {
    color: var(--white);
    font-size: 14px;
    font-weight: 800;
    letter-spacing: -0.05em;
}

.paragraph-primary {
    color: var(--f-grey-9);
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;

    &--formula {
        color: var(--f-grey-10);
    }

    a {
        color: var(--bg-brand);
        text-decoration: underline;
    
        @include breakpoint-min(1200px) {
            &:hover {
                color: var(--white);
                transition: all .3s ease
            }
        }
    }

    span {
        color: var(--white);
        font-weight: 800;
    }
}

.paragraph-terms {
    font-size: 10px;
    font-weight: 400;
    color: var(--f-grey-5);
}

.text-brand {
    color: var(--f-brand);
}
