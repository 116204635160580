.skeleton-loader {
  display: flex;
  overflow: hidden;
  animation: pulse 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;

  &__item {
    flex-shrink: 0  ;
    border-radius: 20px;
    background-color: #222225;
  }

  @keyframes pulse {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
    }
  }
}

