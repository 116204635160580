@keyframes rotating-half {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

.play-responsibly {
  :root & {
    --animation-time: 5s;
    --btn-size: 40px;
    --btn-circle-border-size: 2px;
    --btn-circle-size: calc(var(--btn-size) - var(--btn-circle-border-size) * 2);
    --icon-size: 50%;
  }

  z-index: 51;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  background-color: var(--black);

  &-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1920px;
    margin: 0 auto;
    padding: var(--space-1) var(--space-4);
  }

  &-content {
    color: var(--f-grey-1);
    font-size: 16px;
    font-weight: 400;
    text-align: justify;
    letter-spacing: -0.02em;

    a {
      color: var(--csb-brand, #fbcb00);
      font-weight: 700;
      transition: color 0.3s ease-out;

      &:hover {
        color: var(--bg-brand);
      }
    }
  }

  &-btn {
    overflow: hidden;
    position: relative;
    width: var(--btn-size);
    height: var(--btn-size);
    min-width: var(--btn-size);
    margin: var(--space-4);
    background-color: transparent;
    cursor: pointer;

    .icon-cp {
      z-index: 2;
      position: relative;
      width: 100%;
      height: 100%;
      svg {
        width: var(--icon-size);
        margin: 0 auto;
      }
    }

    .circle {
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 100%;

      &:after {
        position: absolute;
        top: var(--btn-circle-border-size);
        left: var(--btn-circle-border-size);
        width: var(--btn-circle-size);
        height: var(--btn-circle-size);
        background: var(--black);
        border-radius: 50%;
        content: '';
      }

      &-part {
        overflow: hidden;
        position: absolute;
        width: 50%;
        height: 100%;

        em {
          position: absolute;
          border-radius: var(--btn-size);
          width: 100%;
          height: 100%;
          background: var(--white);
          transition: transform 0.25s linear;
        }

        &:nth-child(1) {
          left: 0%;

          em {
            left: 100%;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            transform-origin: 0% 50%;

            animation: rotating-half var(--animation-time) ease-out;
            animation-delay: var(--animation-time);
            animation-fill-mode: forwards;
          }
        }

        &:nth-child(2) {
          left: 50%;

          em {
            left: -100%;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            transform-origin: 100% 50%;
            transition-delay: var(--animation-time);

            animation: rotating-half var(--animation-time) linear;
            animation-fill-mode: forwards;
          }
        }
      }
    }
  }
}
