.share {
  &-alert {
    position: fixed;
    padding: 10px;
    bottom: calc(24px + var(--safe-area-bottom, 0px));
    left: 50%;
    transform: translateX(-50%);
    cursor: default;

    &.is-active {
      display: flex;
    }
  }

  &-dropdown {
    position: absolute;
    top: 44px;
    left: 100%;
    transform: translate(-92%, 0);
    width: 156px;
    padding: 16px 16px 0;

    @include breakpoint-min(1350px) {
      left: 50%;
      transform: translate(-50%, 0);
    }

    &.is-active {
      display: block;
    }

    &::before {
      content: '';
      position: absolute;
      left: 83%;
      top: 0;
      transform: translate(-50%, -100%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 9px 9px;
      border-color: transparent transparent var(--white) transparent;

      @include breakpoint-min(1350px) {
        left: 50%;
      }
    }

    &__list {
    }

    &__item {
      display: flex;
      align-items: center;
      padding-bottom: 16px;

      @include breakpoint-min(1200px) {
        cursor: pointer;

        &:hover .share-dropdown__item-text {
          color: var(--bg-brand);
        }
      }
    }
  }

  &-alert,
  &-dropdown {
    display: none;
    z-index: 12;
    background: var(--white);
    border-radius: var(--radius-primary);
  }

  &-alert,
  &-dropdown__item {
    &-text {
      margin-left: 10px;
      font-size: 11px;
      font-weight: 600;
      line-height: 24px;
      color: var(--f-grey-15);
      white-space: nowrap;
      transition: all 0.3s ease;
    }

    &-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      background-color: var(--bg-primary);
      border-radius: 50%;

      .icon-cp {
        width: 11px;
        height: 11px;
      }
    }

    &--facebook .share-dropdown__item-icon {
      background-color: var(--bg-blue-facebook);
    }

    &--twitter .share-dropdown__item-icon {
      background-color: var(--bg-twitter);
    }
  }
}

.share-dropdown {
  display: none;
  z-index: 10;
  position: absolute;
  top: 44px;
  left: 100%;
  transform: translate(-92%, 0);
  width: 156px;
  padding: 16px 16px 0;
  background: var(--white);
  border-radius: var(--radius-primary);

  @include breakpoint-min(1350px) {
    left: 50%;
    transform: translate(-50%, 0);
  }

  &.is-active {
    display: block;
  }

  &::before {
    content: '';
    position: absolute;
    left: 83%;
    top: 0;
    transform: translate(-50%, -100%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 9px 9px;
    border-color: transparent transparent var(--white) transparent;

    @include breakpoint-min(1350px) {
      left: 50%;
    }
  }

  &__list {
  }

  &__item {
    display: flex;
    align-items: center;
    padding-bottom: 16px;

    @include breakpoint-min(1200px) {
      cursor: pointer;

      &:hover .share-dropdown__item-text {
        color: var(--bg-brand);
      }
    }

    &-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      background-color: var(--bg-primary);
      border-radius: 50%;

      .icon-cp {
        width: 11px;
        height: 11px;
      }
    }

    &-text {
      margin-left: 10px;
      font-size: 11px;
      font-weight: 600;
      color: var(--f-grey-15);
      white-space: nowrap;
      transition: all 0.3s ease;
    }

    &--facebook .share-dropdown__item-icon {
      background-color: var(--bg-blue-facebook);
    }

    &--twitter .share-dropdown__item-icon {
      background-color: var(--bg-twitter);
    }
  }
}
