:root {
  --space-1: 4px;
  --space-2: 8px;
  --space-3: 12px;
  --space-4: 16px;
  --space-5: 20px;
  --space-6: 24px;
  --space-7: 32px;
  --space-8: 40px;
  --space-9: 48px;
  --space-10: 56px;
  --space-11: 64px;
  --space-12: 80px;
  --space-13: 96px;
  --space-14: 128px;
  --space-15: 160px;
  --space-16: 192px;
  --space-17: 224px;
  --space-18: 256px;
  --space-19: 512px;

   //RADIUSES
   --radius-xs: 8px;
   --radius-s: 12px;
   --radius-m: 16px;
   --radius-l: 24px;
   --radius-xl: 32px;

  .side-menu {
    ////margin
    //--m-1: 10px;
    //--m-2: 20px;
    //
    //// padding
    //--p-1: 10px;
    //--p-2: 21px;
    //--p-3: 23px;
    //--p-4: 15px;
    //--p-5: 18px;
    //radiuses
    --radius-1: 12px;
    //colors
    --btn-message-bg: var(--bg-brand);
    --btn-message-bg-hover: var(--brand-50);
    --btn-message-svg-bg1: var(--neutral-dark-2);
    --btn-message-svg-bg2: var(--neutral-dark-0);
    --btn-message-svg-bg-hover: var(--neutral-dark-3);
  }

  --radius-xs: 8px;
  --radius-s: 12px;
  --radius-m: 16px;
  --radius-l: 24px;
  --radius-xl: 32px;

  .right-menu {

    //--m-1: 10px;
    //--m-2: 15px;
    //--m-3: 22px;
    //--m-4: 30px;
    //--m-5: 40px;

    //--p-1: 10px;
    //--p-2: 15px;

    --radius-1: 12px;
    --radius-2: 20px;
    --radius-3: 4px;
  }

  --white: #fff;
  --black: #000;

  //COLOR NEUTRAL
  --neutral-10: #e6e6ea;
  --neutral-20: #cbcbd2;
  --neutral-30: #b0b0ba;
  --neutral-40: #9595a2;
  --neutral-50: #79798b;
  --neutral-60: #616170;
  --neutral-70: #4a4a55;
  --neutral-80: #323239;
  --neutral-85: #27272d;
  --neutral-90: #1b1b1f;
  --neutral-100: #0e0e10;

  //COLOR TRANSPARENT
  --transparent-10: hsl(0deg, 0%, 100%, 0.9);
  --transparent-20: hsl(0deg, 0%, 100%, 0.8);
  --transparent-30: hsl(0deg, 0%, 100%, 0.7);
  --transparent-40: hsl(0deg, 0%, 100%, 0.6);
  --transparent-50: hsl(0deg, 0%, 100%, 0.5);
  --transparent-60: hsl(0deg, 0%, 100%, 0.4);
  --transparent-70: hsl(0deg, 0%, 100%, 0.3);
  --transparent-80: hsl(0deg, 0%, 100%, 0.2);
  --transparent-90: hsl(0deg, 0%, 100%, 0.1);
  --transparent-100: hsl(0deg, 0%, 100%, 0);

  //COLOR BRAND
  --brand-10: #fffefa;
  --brand-20: #fff4c7;
  --brand-30: #ffeb94;
  --brand-40: #ffe161;
  --brand-50: #ffd72e;
  --brand-60: #fbcd00;
  --brand-70: #c7a100;
  --brand-80: #947800;
  --brand-90: #614e00;
  --brand-100: hsl(49deg, 100%, 49.22%, 0.2);

  //COLOR SUCCESS
  --success-10: #e5fff6;
  --success-20: #b3ffe3;
  --success-30: #80ffd1;
  --success-40: #4dffbe;
  --success-50: #1affac;
  --success-60: #00e592;
  --success-70: #00b372;
  --success-80: #008051;
  --success-90: #004d31;
  --success-100: hsl(158.25deg, 100%, 44.9%, 0.15);

  //COLOR INFO
  --info-10: #ccebff;
  --info-20: #99d7ff;
  --info-30: #66c4ff;
  --info-40: #33b0ff;
  --info-50: #009cff;
  --info-60: #007dcc;
  --info-70: #005e99;
  --info-80: #003e66;
  --info-90: #001f33;
  --info-100: hsla(203, 100%, 40%, 0.15);

  //COLOR WARNING
  --warning-10: #ffeacc;
  --warning-20: #ffd699;
  --warning-30: #ffc166;
  --warning-40: #ffad33;
  --warning-50: #ff9800;
  --warning-60: #cc7a00;
  --warning-70: #995b00;
  --warning-80: #663d00;
  --warning-90: #331f00;
  --warning-100: hsla(36, 100%, 40%, 0.15);

  //COLOR ERROR
  --error-10: #fff0f0;
  --error-20: #ffbdbd;
  --error-30: #ff8a8a;
  --error-40: #ff5757;
  --error-50: #ff2424;
  --error-60: #f00000;
  --error-70: #bd0000;
  --error-80: #8a0000;
  --error-90: #570000;
  --error-100: hsla(0, 100%, 47%, 0.15);

  //COLOR ESPORT
  --esport-10: #ccffe7;
  --esport-20: #99ffce;
  --esport-30: #66ffb6;
  --esport-40: #33ff9d;
  --esport-50: #01ff85;
  --esport-60: #00cc6a;
  --esport-70: #009950;
  --esport-80: #006635;
  --esport-90: #00331b;
  --esport-100: hsla(151, 100%, 40%, 0.15);
}