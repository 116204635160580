.subpages-header {
  overflow-y: hidden;

  .header {
    background-color: transparent;
    &::after {
      content: none;
    }
  }

  .header-container {
    z-index: 30;
    background-color: transparent;
  }
}

.bets-header-index {
  .header-container {
    z-index: 1;
  }
}

.header-container {
  z-index: 12;
  position: fixed;
  top: 0;
  left: 0;
  padding-top: calc(0px + var(--top-space-height) + var(--safe-area-top));
  width: 100%;
  max-width: var(--container-max-width);
  background-color: var(--header-bg, var(--bg-body));

  @include breakpoint-min(768px) {
    left: 50%;
    transform: translateX(-50%);
  }
}

.header {
  --header-logo-size-s: 70px;
  display: block;
  padding: 10px var(--container-side-padding) 15px 15px;
  background-color: var(--header-bg, var(--bg-body));

  &__inner {
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 15px;
    border-bottom: var(--header-border-bottom, 2px solid var(--bg-primary));
    min-height: 55px;
  }

  &__menu {
    z-index: 1000;
    position: relative;
    width: 25px;
    height: 25px;
    flex-shrink: 0;
    cursor: pointer;

    &-arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
      position: relative;
      width: 25px;
      height: 25px;
      flex-shrink: 0;
      cursor: pointer;
      .icon-arrow-back {
        path {
          fill: var(--white);
        }
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      width: 25px;
      height: 2px;
      background-color: var(--hamburger-bg, var(--white));
      transition: 0.25s ease;
    }

    &-line {
      position: absolute;
      top: 50%;
      left: 0;
      width: 25px;
      height: 2px;
      background-color: var(--hamburger-bg, var(--white));
      transition: 0.25s ease;

      &-top {
        transform: translateY(calc(-100% - 3px));
      }

      &-bottom {
        left: 7px;
        right: 0;
        width: 18px;
        transform: translateY(calc(100% + 3px));
      }
    }

    &.is-active {
      &::after {
        transform: rotate(-45deg);
      }

      .header__menu-line-middle {
        transform: rotate(45deg);
      }

      .header__menu-line-top,
      .header__menu-line-bottom {
        transform: translateY(0);
        opacity: 0;
      }
    }
  }

  &-logo {
    display: flex;
    flex-direction: column;

    &__link {
      display: flex;
      align-items: center;
      width: 83px;
      height: 19px;
      margin-left: 12px;
      margin-right: var(--space-2);
      background-repeat: no-repeat;

      @include breakpoint-max(359px) {
        width: var(--header-logo-size-s, 36px);
      }
    }

    &__icon {
      &-vip {
        width: 36px;
      }
    }

    &__title {
      z-index: 1;
      position: absolute;
      bottom: 0;
      left: 25px;
      transform: translateY(50%);
      padding: 0 7px 0 12px;
      background-color: var(--header-bg, var(--bg-body));
      color: var(--logo-title-color, var(--white));
      font-size: 11px;
      font-weight: bold;
      font-style: italic;
      line-height: 15px;
      letter-spacing: 0.975em;
      text-transform: uppercase;
    }

    &-line {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: var(--white);
      transition: 0.25s ease;

      &-top {
        transform: translateY(calc(-100% - 3px));
      }

      &-bottom {
        left: auto;
        right: 0;
        width: 18px;
        transform: translateY(calc(100% + 3px));
      }
    }

    &.is-active {
      &::after {
        transform: rotate(-45deg);
      }

      .header__menu-line-middle {
        transform: rotate(45deg);
      }

      .header__menu-line-top,
      .header__menu-line-bottom {
        transform: translateY(0);
        opacity: 0;
      }
    }
  }

  &-nav {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .btn-register {
    width: 100px;
    height: 38px;
    font-size: 14px;
    margin-right: 7px;
  }

  .inbox-indicator {
    &:after {
      content: '';
      position: absolute;
      top: calc(0% + 4px);
      right: calc(0% + 4px);
      width: 8px;
      height: 8px;
      background-color: var(--bg-inbox, var(--bg-red-2));
      border-radius: 50%;
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    transform: translateY(95%);
    background: linear-gradient(0deg, rgb(16 14 16 / 0%) 0, var(--header-bg, var(--bg-body)) 100%);
  }
}

.cash-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 38px;
  max-width: 130px;
  width: 100%;
  margin: 0 7px;
  padding: 6px 6px 6px 9px;
  line-height: 1.1;
  font-size: 12px;
  font-weight: 700;
  border: var(--cash-border, 2px solid var(--bg-green));
  border-radius: var(--cash-radius, 15px);
  letter-spacing: -0.05em;

  @include breakpoint-min(385px) {
    width: 150px;
  }

  &__value {
    display: block;
    color: var(--cash-value-color, #fff);
  }

  &__value-bonus {
    overflow: hidden;
    max-width: 110px;
    color: var(--cash-bonus-color, var(--f-grey-10));
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  @include breakpoint-max('390px') {
    &__value-bonus {
      overflow: hidden;
      display: block;
      max-width: 80px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    margin-left: 5px;
    border-radius: var(--cash-btn-radius, 15px);
    background: var(--cash-btn-bg, #00b372);

    .icon-cp {
      width: 12px;
      height: 12px;

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.vip-user-frame {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 50%;
  bottom: 0;
  padding: 2px 4px;
  background-color: var(--bg-brand);
  border-radius: 10px;
  transform: translate(50%, 0);
}

.header-bar {
  z-index: 13;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  max-height: 58px;
  max-width: var(--container-max-width, 800px);
  padding: 12px;
  background: var(--header-bar-bg, var(--white));
  border-bottom-left-radius: var(--header-bar-radius, var(--radius-primary));
  border-bottom-right-radius: var(--header-bar-radius, var(--radius-primary));

  @include breakpoint-min(800px) {
    left: 50%;
    transform: translateX(-50%);
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 34px;
    height: 34px;
    margin-right: 8px;
    margin-left: 4px;
    background-color: var(--icon-bg, var(--body));
    background-size: 25px;
    border-radius: var(--icon-radius, var(--radius-primary));

    .icon {
      &-cp {
        display: flex;
        width: 25px;
      }
      &-logo-signet {
        path {
          fill: var(--logo-color, revert-layer);
        }
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    line-height: 120%;
  }

  &__title {
    color: var(--title-color, var(--black));
    font-weight: 700;
    font-size: 16px;
  }

  &__subtitle {
    margin-top: 4px;
    color: var(--subtitle-color, var(--black));
    font-size: 10px;
    font-weight: 600;
  }

  &__close {
    display: flex;
    width: 32px;

    .icon-cp {
      width: 12px;
      height: 12px;
      margin: 0 auto;
      cursor: pointer;

      path,
      polygon {
        fill: var(--close-icon-fill, var(--f-grey));
      }
    }
  }

  &__btn {
    margin-left: auto;

    .btn {
      max-width: 150px;
      height: 34px;
      padding: 0 31px;
      border-color: var(--btn-border-color);
      border-radius: var(--btn-radius, var(--radius-primary));
      color: var(--btn-color, var(--white));
      font-weight: 700;
      font-size: 12px;
      text-transform: initial;
      background-color: var(--btn-bg, var(--primary-green));

      .icon-cp {
        width: 12px;
        height: 14px;
        margin-right: 8px;

        &.icon-android-logo {
          width: 18px;
        }

        &.icon-apple {
          height: 16px;
        }

        path {
          fill: var(--btn-color, var(--white));
        }
      }
    }
  }
}

.pwa-install-ios {
  position: fixed;
  top: var(--space-4, 16px);
  left: 50%;
  width: 268px;
  max-width: 80%;
  padding: var(--space-2, 8px) var(--space-3, 12px);
  border-radius: var(--radius-2, 8px);
  text-align: center;
  letter-spacing: -0.02em;
  background: var(--grey-primary, #27272d);
  transform: translateX(-50%);

  &__wrapper {
    z-index: 1004;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(2, 2, 3, 0.8);
  }

  &.is-safari {
    top: unset;
    bottom: var(--space-4, 16px);
    &::after {
      top: unset;
      bottom: -6px;
    }
  }

  &::after {
    display: inline-block;
    z-index: -1;
    position: absolute;
    top: -6px;
    left: 50%;
    width: 16px;
    height: 16px;
    content: ' ';
    background: var(--grey-primary, #27272d);
    transform: translateX(-50%) rotate(-45deg);
  }

  .icon-cp {
    display: inline-block;
    width: 20px;
    height: 18px;
    vertical-align: bottom;

    &.icon-dots-menu {
      width: 20px;
      height: 14px;
    }

    path,
    circle {
      fill: var(--white);
    }
  }
}

.deposit-bar {
  z-index: 13;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: var(--container-max-width);
  margin-bottom: var(--space-3);
  padding: var(--space-3) var(--space-4);
  background-color: var(--deposti-bar-bg);
  border-radius: var(--radius-s);

  .icon-cp {
    width: 10px;
    height: 10px;
    margin-right: var(--space-3);
    cursor: pointer;
    svg,
    path {
      fill: var(--basic-color);
    }
  }

  &__content {
    overflow: hidden;
    display: flex;
    flex-flow: column;
    margin-right: auto;
  }

  &__title {
    overflow: hidden;
    color: var(--slide-subtitle-color, var(--basic-color));
    font-size: 10px;
    font-weight: 600;
    white-space: nowrap;
    text-overflow: ellipsis;
    letter-spacing: var(--ls-2);
  }

  .btn {
    height: 30px;
    min-width: 90px;
    margin-left: var(--space-3);
    background-color: var(--btn-bg, var(--basic-color));
    color: var(--btn-color, var(--neutral-100));
    font-size: 10px;
    font-weight: 700;
    letter-spacing: var(--ls-2);
    border: none;
    border-radius: var(--btn-radius, var(--radius-xs));
  }
}
