.bottom-sheet {
    display: none;
    z-index: 30;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    min-height: 100%;
    height: 100%;
  
  
  
    &__overlay {
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  
    &__layout {
      z-index: 2;
      position: absolute;
      transform: translateY(100%);
      display: flex;
      flex-flow: column;
      width: 100%;
      bottom: 0;
      padding: var(--space-4) var(--space-3) 0;
      background-color: var(--bottom-sheet-bg, var(--body-bg));
      border-radius: var(--radius-1) var(--radius-1) 0 0;
      animation: bottom-sheet-show cubic-bezier(0, 0, 0.2, 1) 0.2s forwards;
      animation-delay: 0.15s;
    }
  
    &.is-active {
      display: block;
    }
}
  
.bottom-sheet-header {
    display: flex;
    justify-content: space-between;
    min-height: 22px;
    margin-bottom: var(--space-5);
  
    &__icon {
      display: flex;
      height: 13px;
  
      &--title {
        height: 16px;
        margin-right: var(--space-3);
      }
  
      &--action {
        margin-left: var(--space-6);
      }
  
      &.icon-arrow-back {
        height: 21px;
        width: 21px;
      }
  
      &.icon-stats {
        width: 19px;
      }
  
      &.icon-bet-booster,
      &.icon-player-bets {
        width: 16px;
      }
  
      &.icon-close-thick {
        width: 13px;
        height: 13px;
      }
  
      &.icon-close-thick,
      &.icon-arrow-back path {
        fill: var(--white);
      }
    }
  
    &__title {
      overflow: hidden;
      font-size: 16px;
      font-weight: 800;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  
    &__title,
    &__subtitle {
      letter-spacing: var(--ls-1);
    }
  
    &__subtitle {
      color: var(--neutral-light-3);
      font-size: 13px;
      font-weight: 700;
    }
  
    &__col {
      overflow: hidden;
      display: flex;
      align-items: center;
    }
}
  
.bottom-sheet-content {
    position: relative;
    overflow: auto;
    max-height: 100%;
    height: 100%;
    padding-bottom: var(--space-4);
}

.bottom-sheet--full-size {
    --open-game-gap: 80px;
}
  
@keyframes bottom-sheet-show {
    from {
      transform: translateY(100%);
    }
  
    to {
      transform: translateY(0);
    }
}
  
