//ENV-VARIABLES
@import 'env-variables';

// ABSTRACT
@import 'casino/mobile-web/lvbet/abstract/variables';
@import 'casino/common/lvbet/abstract/colors';
@import 'casino/common/lvbet/abstract/mixins';
@import 'casino/common/lvbet/abstract/typography';

// BASICS
@import 'casino/common/lvbet/base/resets';
@import 'casino/mobile-web/lvbet/base/basic';
@import 'casino/mobile-web/lvbet/base/buttons';
@import 'casino/common/lvbet/base/icons';
@import 'casino/common/lvbet/base/flags';

// ELEMENTS
@import 'casino/mobile-web/lvbet/elements/countdown';
@import 'casino/mobile-web/lvbet/elements/checkboxes';
@import 'casino/mobile-web/lvbet/elements/dropdown';
@import 'casino/common/lvbet/elements/share';
@import 'casino/common/lvbet/elements/variables';

// SHARED COMPONENTS
@import 'shared/mobile-web/lvbet/header/header';
@import 'shared/mobile-web/lvbet/bottom-nav/bottom-nav';
@import 'shared/mobile-web/lvbet/left-menu/left-menu';
@import 'shared/mobile-web/lvbet/right-menu/right-menu';
@import 'shared/mobile-web/lvbet/breadcrumbs/breadcrumbs';
@import 'shared/common/lvbet/bullet-loader/bullet-loader';
@import 'shared/common/lvbet/skeleton-loader/skeleton-loader';
@import 'shared/common/lvbet/self-exclusion-reminder/self-exclusion-reminder';
@import 'shared/common/lvbet/bottom-sheet/bottom-sheet';
@import 'shared/mobile-web/lvbet/widgets/optimove';
@import 'shared/mobile-web/lvbet/bottom-sheet/bottom-sheet';

// COMPONENTS
@import 'casino/mobile-web/lvbet/components/datepicker/datepicker';
@import 'casino/mobile-web/lvbet/components/footer/footer';
@import 'casino/mobile-web/lvbet/components/search/search';
@import 'casino/mobile-web/lvbet/components/jackpot-home-widget/jackpot-home-widget';

.header-bar {
  --header-bar-bg: #342b14;
  --icon-bg: var(--black);
  --icon-radius: 8px;
  --header-bar-radius: 0;
  --btn-radius: 12px;
  --btn-bg: var(--basic-color);
  --close-icon-fill: var(--basic-color);
  --title-color: var(--f-white);
  --subtitle-color: var(--basic-color);
  --btn-border-color: var(--basic-color);
  --btn-color: var(--black);
  --logo-color: var(--white);
}
