.bullet-loader {
    display: block;
    position: relative;
    width: 64px;
    height: 64px;
    margin: 0 auto;

    &__item {
        position: absolute;
        top: 27px;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background: var(--bg-brand);
        animation-timing-function: cubic-bezier(0, 1, 1, 0);

        &:nth-child(1) {
            left: 6px;
            animation: animation-loader-1 0.6s infinite;
        }

        &:nth-child(2) {
            left: 6px;
            animation: animation-loader-2 0.6s infinite;
        }

        &:nth-child(3) {
            left: 26px;
            animation: animation-loader-2 0.6s infinite;
        }

        &:nth-child(4) {
            left: 45px;
            animation: animation-loader-3 0.6s infinite;
        }
    }
}

@keyframes animation-loader-1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes animation-loader-2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(19px, 0);
    }
}

@keyframes animation-loader-3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

