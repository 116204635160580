
.bottom-sheet  {
  max-width: var(--container-max-width);
  left: 0;
  @include breakpoint-min(800px) {
    left: 50%;
    transform: translateX(-50%);
  }
}

.bottom-sheet__layout  {
  overflow: hidden;
  left: 0;
}

.bottom-sheet__overlay {
  background-color: rgba(125, 125, 125, 0.5);
}