.checkboxes {
    display: grid;
    grid-gap: 16px;
    color: var(--f-grey-9);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.05em;

    &__single {
        display: flex;
        align-items: center;
    }

    &__box {
        width: 34px;
        height: 34px;
        margin-right: 12px;
        border: 3px solid var(--line-primary);
        border-radius: 50%;
    }

    .is-active {
        --line-primary: var(--bg-brand);
    }
}