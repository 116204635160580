.datepicker {
  z-index: 11;
  position: fixed;
  bottom: var(--picker-position, 0);
  left: var(--picker-position, 0);
  transform: translate(calc(var(--picker-position, 0) * -1), var(--picker-position, 0));
  width: 100%;
  max-width: var(--picker-max-width);
  display: block;
  padding: 10px calc(19px + var(--safe-area-left, 0px));
  background-color: var(--picker-body);
  border-radius: var(--radius-primary);
  box-shadow: 0px 0px 8px 8px #0E0E1033;

  &-days-entry.prev-month {
    opacity: 0.4;
  }

  &-btn-switch {
    position: relative;
    display: flex;
    height: 34px;
    width: 56px;
    background-color: var(--picker-body);
    border-radius: var(--radius-primary);

    .icon-cp {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 16px;
      height: 16px;
      path {
        fill: var(--icon-switch-fill);
      }
    }
  }

  &-btn-choose {
    height: 40px;
    width: 100%;
    margin-top: 10px;
    color: var(--white);
    background-color: var(--btn-choose-bg);
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: var(--radius-primary);
  }
}

.datepicker-bar {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  &-title {
    margin: 0 auto;
    color: var(--title-color);
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.05em;
    text-transform: uppercase;
  }
}

.datepicker-grid {
  &-row {
    display: flex;
    margin-bottom: 10px;
  }

  &-entry {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% / 7);
    margin-right: 10px;
    color: var(--entry-color);
    font-size: 13px;
    font-weight: 700;
    letter-spacing: -0.025em;
    &:last-child {
      margin-right: 0;
    }
  }
}

.datepicker-header-entry {
  color: var(--white);
  text-transform: uppercase;
}

.datepicker-days-entry,
.datepicker-years-entry {
  height: 36px;
  border: var(--entry-border);
  border-radius: var(--radius-primary);

  &.is-active {
    color: var(--entry-color-active);
    border-color: var(--entry-active-border);
    background-color: var(--entry-active-bg);
  }
}
