.countdown {
    padding-bottom: 23px;
    margin-bottom: 15px;
    letter-spacing: -0.05em;

    &__title {
        margin-bottom: 2px;
        color: var(--bg-brand);
        font-size: 14px;
        font-weight: 700;
    }

    &__content {
        display: flex;
        color: var(--white);
        font-size: 22px;
        font-weight: 800;
    }

    &__entry {
        position: relative;
    }

    &__entry span {
        position: absolute;
        bottom: -3px;
        left: 50%;
        transform: translate(-50%, 100%);
        color: var(--bg-primary);
        font-size: 16px;
        font-weight: 700;
    }
}

.countdown-alt {
    z-index: 1;
    position: relative;
    display: flex;
    margin-top: 25px;
    color: var(--white);
    font-size: 17px;
    font-weight: 700;
    letter-spacing: -0.05em;

    &__day {
        margin-right: 13px;
    }

    &__entry {
        padding: 0 10px;
        background-color: var(--bg-body);
        border-radius: var(--radius-primary);
    }

    &__entry span {
        color: var(--f-grey-8);
        font-size: 13px;
    }
}
