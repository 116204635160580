@import './fonts';

* {
  box-sizing: border-box;
}

html {
  background-color: var(--bg-body);
}

body {
  overflow-x: hidden;
  position: relative;
  display: block;
  min-height: 100vh;
  padding-top: calc(var(--header-height) + var(--top-space-height) + var(--safe-area-top));
  padding-bottom: calc(var(--bottom-nav-height) + var(--safe-area-bottom) + 32px);
  color: var(--white);
  background-color: var(--bg-body);
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  touch-action: pan-x pan-y;
  -webkit-tap-highlight-color: transparent;

  &.app-iframed {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &.has-header-bar {
    --top-space-height: 56px;
  }

  &.breadcrumb-active {
    --header-height: 172px;
  }

  &.hide-main-nav {
    .main-nav {
      display: none;
    }

    .filters-navbar {
      display: flex;
    }
  }

  &.disable-scroll,
  &.no-scroll {
    overflow: hidden;
    width: 100%;
    height: 100vh;
  }

  &.modal-open {
    overflow: hidden;
    width: 100%;
    height: 100vh;
  }
}

::-webkit-scrollbar {
  display: none;
}

.container-fluid {
  max-width: 768px;
  margin: 0 auto;
  padding: 0 10px;
}

.app-container {
  overflow-x: hidden;
  max-width: var(--container-max-width);
  margin: 0 auto;
  padding: 0 10px 10px;
}

.toggle-inline {
  position: relative;
  width: 43px;
  height: 20px;
  flex-shrink: 0;
  margin-left: 14px;
  border-radius: 9px;
  background-color: #747474;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;

  &::after {
    position: absolute;
    top: 50%;
    transform: translate(2px, -50%);
    display: inline-block;
    width: 18px;
    height: 18px;
    background-color: var(--white);
    border-radius: 50%;
    transition: transform 0.2s ease-in-out;
    content: '';
  }

  &.is-active {
    background-color: var(--bg-primary);
  }

  &.is-active::after {
    transform: translate(23px, -50%);
  }
}

input {
  border: none;
  outline: none;
}

.buttons-wrapper {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.heading {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 800;
  letter-spacing: -0.05em;
  color: var(--white);
}

.deposit-bar {
  --deposti-bar-bg: rgba(251, 205, 0, 0.15);
  --btn-color: #0e0e10;
}
